import { Component, OnInit } from '@angular/core'
import { AuthService } from '@app/_services/auth.service'
import { ActivatedRoute, Router } from '@angular/router'
import { DataService } from '@app/_services/data.service'
import { ViewChild, ElementRef } from '@angular/core'
import * as $ from 'node_modules/jquery'
import {
  GroupDeleteResponse,
  GroupUserShareResponse,
  NewGroupResponse,
  UserShareData,
} from '../_definitions/home-groups.definitions'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { Options } from '../_definitions/shared-definitions/nz-select.definitions'
import { FunctionService } from '../_services/function.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @ViewChild('groupNameBox') public groupNameBox: ElementRef
  @ViewChild('descriptionBox') public descriptionBox: ElementRef

  constructor(
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    public data: DataService,
    private message: NzMessageService,
    private modal: NzModalService,
    private functionService: FunctionService,
  ) {
    var shelf = this
    $(document)
      .keydown(function (event) {
        if (event.keyCode == 44) {
          // printScreen
          // TODO: OS KEZELI !!!
        }
      })
      .keyup(function (event) {
        if (event.keyCode == 44) {
          shelf.copyToClipboard()
          var attr = document
            .getElementById('photo-gallery-alt')
            .getAttribute('class')
          console.info('PRTSCR', attr.toString())
        }
      })
  }

  public userGroups: any
  public otherGroups: any
  public sharingLike: any[] = []
  public newGroup: boolean = false
  public up: boolean = false
  public shid: string = ''
  public delGroups: boolean = false
  public delIdList: any[] = []
  public isEmptyUserGroup: boolean = true
  public isEmptyOtherGroups: boolean = true
  public info: string = ''
  public userData: boolean = false
  public groupData: boolean = false
  public isLoadingGroups: boolean = true

  ngOnInit() {
    this.getGroupsLoading()
    localStorage.removeItem('gname')
    localStorage.removeItem('gid')
    localStorage.removeItem('abid')
  }

  //--------------------------------------------------------------------- Functions -----------------------------------------------------------------------

  private copyToClipboard() {
    var aux = document.createElement('input')
    aux.setAttribute(
      'value',
      'Kérlek ne használd ezen az oldalon a PrtScr funkciót!',
    )
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    // alert("A nyomtatási képernyő letiltva!");
    // $("#onPS").click();
    $(window)
      .focus(function () {
        $('#onPS').show()
      })
      .blur(function () {
        $('#onPS').hide()
      })
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------- Procedures ----------------------------------------------------------------------

  onScroll() {
    // on scroll
  }

  // groups deleted events
  chooseGroupsItems() {
    // kijelöléshez szükséges checkboxok megjelenítése
    this.delGroups = true
    this.delIdList = []
  }
  groupSelected(e) {
    // csoport kiválasztás, checkbox eseménye
    this.delIdList = []
    var deleteIdList: any = document.getElementsByClassName('inp')
    for (let item of deleteIdList) {
      if (item.checked) {
        this.delIdList.splice(0, 0, item.id)
        // TODO: splice
        // távolítson el i től 1 elemet, vagy beszúrás: (i,0,'valami') vagy csere: (i,1,'valamire')
      }
    }
  }
  public deleteGroupsCancelClick(): void {
    this.restoreDefaultValues()
  }

  public onDeleteGroupButtonClicked(): void {
    if (this.delIdList?.length > 0) {
      const modal: NzModalRef = this.modal.create({
        nzTitle: 'CSOPORT TÖRLÉS',
        nzContent: 'Biztos hogy törölni szeretnéd a kijelölt csoportokat?',
        nzFooter: [
          {
            label: 'Nem',
            // shape: 'round',
            onClick: () => {
              modal.destroy()
            },
          },
          {
            label: 'Igen',
            type: 'primary',
            danger: true,
            // loading: false,
            onClick: () => {
              modal.destroy()
              this.onDeleteGroup()
            },
          },
        ],
      })
    }
  }

  private onDeleteGroup(): void {
    this.data
      .deleteGroups(this.delIdList)
      .subscribe((response: GroupDeleteResponse) => {
        if (response.status === 200) {
          // TODO: valameilyik csoport nem üres vagy nincs hozzáférése
          if (
            response.NotEmpty.length > 0 ||
            response.NoPermission.length > 0
          ) {
            const messageContent =
              'A KIJELÖLT CSOPORTOK KÖZÖTT AKAD OLYAN, AMELYIK NEM TÖRÖLHETŐ! \n' +
              ' A csoport nem üres, lehet másnak is vannak itt albumai, vagy \n' +
              ' nem te hoztad létre, vagy mert nincs jogosultság beállítva. \n' +
              ' Minden más törölve!'
            const modal: NzModalRef = this.modal.create({
              nzTitle: 'TÖRLÉS INFORMÁCIÓ',
              nzContent: messageContent,
              nzFooter: [
                {
                  label: 'Értem',
                  type: 'primary',
                  danger: false,
                  onClick: () => {
                    modal.destroy()
                    this.getGroupsLoading()
                    this.delGroups = false
                  },
                },
              ],
            })
          } else {
            this.message.create(
              'success',
              'A KIJELÖLT CSOPORTOK SIKERESEN TÖRÖLVE!',
            )
            this.getGroupsLoading()
            this.delGroups = false
          }
        }
      })
  }

  openAlbums(gname, gid) {
    localStorage.setItem('gname', gname)
    localStorage.setItem('gid', gid)
    this.router.navigate(['/albums'])
  }

  getGroupsLoading() {
    // const loggedInUserGroups = this.route.snapshot.data?.groups[0]?.data
    //   ? this.route.snapshot.data.groups[0].data
    //   : null

    // const CategorizedGroups = this.route.snapshot.data?.groups[1]?.data
    //   ? this.route.snapshot.data.groups[1].data
    //   : null
    // console.log(
    //   'groups',
    //   loggedInUserGroups,
    //   CategorizedGroups,
    //   this.route.snapshot.data,
    // )
    this.isEmptyUserGroup = true
    this.isLoadingGroups = true
    this.isEmptyOtherGroups = true
    this.data.getHomeDatas().subscribe((res) => {
      if (res) {
        this.isLoadingGroups = false
        if (res?.loggedInUserGroups?.data?.length > 0) {
          this.isEmptyUserGroup = false
          // console.log('ludata:',res.loggedInUserGroups.data)
          this.userGroups = res.loggedInUserGroups.data
        }
        if (res?.CategorizedGroups?.data?.length > 0) {
          this.otherGroups = res.CategorizedGroups.data
          this.isEmptyOtherGroups = false
        }
      }
    })
  }

  private getSelectUserShare(): void {
    this.data.getUserShare().subscribe((response: GroupUserShareResponse) => {
      this.sharingLike = []
      if (response.status === 200) {
        response.data.forEach((element: UserShareData) => {
          let elements = {
            ...element,
            value: element.descriptation,
            id: element.shId,
          }
          this.sharingLike.push(elements)
        })
      }
    })
  }

  newGroupClick() {
    this.newGroup = true
    this.getSelectUserShare()
  }

  private checkingOfMandatoryData(): void {
    if (this.groupNameBox.nativeElement.value !== '' && this.shid !== '') {
      this.up = true
    } else {
      this.up = false
    }
  }

  public sharingSelectClick(selected: Options): void {
    this.shid = selected?.id ? String(selected.id) : ''
    this.checkingOfMandatoryData()
  }

  groupNameBoxValue(event) {
    this.groupNameBox.nativeElement.value = this.functionService.capitalizeFirstLetter(
      event.target.value,
    )
    this.checkingOfMandatoryData()
  }

  public saveGroup(): void {
    if (this.groupNameBox.nativeElement.value !== '' && this.shid !== '') {
      this.data
        .setGroup(
          this.groupNameBox.nativeElement.value,
          this.descriptionBox.nativeElement.value,
          this.shid,
        )
        .subscribe((response: NewGroupResponse) => {
          console.log('RES DATA:', response)
          if (
            response &&
            response.status === 200 &&
            response.statusText === 'successfully'
          ) {
            this.getGroupsLoading()
            this.restoreDefaultValues()
            this.message.create(
              'success',
              'AZ ÚJ CSOPORT SIKERESEN LÉTREHOZVA!',
            )
          }
          if (response && response.status === 300) {
            this.info = 'A csoport név már létezik!'
          }
          if (response && response.status === 403) {
            this.info = 'A művelethez nincs megfelelő engedély beállítva!'
          }
        })
    }
  }

  public onCancelButtonClick() {
    this.restoreDefaultValues()
  }

  private restoreDefaultValues(): void {
    this.delGroups = false
    this.newGroup = false
    this.info = ''
    this.shid = ''
    this.delIdList = []
    this.up = false
    if (this.groupNameBox?.nativeElement?.value !== undefined) {
      this.groupNameBox.nativeElement.value = ''
    }
  }
}
