import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutExtension'
})
export class CutExtensionPipe implements PipeTransform {
  transform(value: string): string {
    const dotIndex = value.lastIndexOf('.');
    return dotIndex >= 0 ? value.substring(0, dotIndex) : value;
  }
}
