<h3 class="page-title">
  <div class="title-back">
    <a (click)="groupClick()">CSOPORTOK</a>
  </div>
  <div class="title-separator">/</div>
  <div class="title-back">
    <a (click)="albumsClick()">
      ALBUMOK
    </a>
  </div>
  <div class="title-separator">/</div>
  <div class="title">
    KÉPEK
  </div>
</h3>

<div class="albumName">{{ groupName }}&nbsp;/&nbsp;{{ albumName }}</div>
<!-- <hr class="whiter" /> -->
<br />

<div
  class="block-area search-results"
  *ngIf="images"
  style="
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #fda12b91 #4f4f5abd;
  "
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
>
  <div id="photo-gallery-alt" class="row groups">
    <div
      class="gallery-item"
      *ngFor="let data of images.data"
      style="
        -webkit-animation: swing-in-top-fwd 1s;
        background-color: #0000004f;
        padding: 5px;
      "
    >
      <a style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <img [src]="data.picture" alt="Sample Image Title" />

        <span
          style="cursor: pointer;"
          data-toggle="modal"
          href="#modalImages"
          (click)="clickImage(data.index, data.picId)"
        >
          <i
            class="fa fa-search"
            data-toggle="modal"
            href="#modalImages"
            (click)="clickImage(data.index, data.picId)"
            style="cursor: pointer;"
          ></i>
        </span>
      </a>
    </div>
  </div>
</div>

<div
  *ngIf="!emptyImage && images"
  class="modal fade"
  id="modalImages"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  onContextMenu="return false;"
  data-backdrop="false"
>
  <div
    class="modal-dialog modal-lg"
    style="display: contents; padding: inherit;"
  >
    <div class="modal-content content">
      <div class="modal-header" style="z-index: 1;">
        <div class="modal-title col-md-5">
          Képek összesen:
          <label style="color: burlywood;">
            &nbsp;{{ imgAllNumber }}&nbsp;/&nbsp;{{ imgNumber }}
          </label>
        </div>

        <div class="media-body col-md-6">
          <div class="media setbuttons" id="top-menu">
            <label
              style="
                color: burlywood;
                text-transform: uppercase;
                margin-right: 5px;
              "
            >
              Beállítás mint
            </label>

            <div class="pull-left tm-icon">
              <a
                matTooltip="Csoportkép"
                matTooltipPosition="below"
                data-drawer="messages"
                class="drawer-toggle"
                href=""
                (click)="setGroupImageClick()"
              >
                <i class="fa fa-users"></i>
                <!-- <span>Csoportkép</span> -->
              </a>
            </div>

            <div class="pull-left tm-icon">
              <a
                matTooltip="Albumkép"
                matTooltipPosition="below"
                data-drawer="messages"
                class="drawer-toggle"
                href=""
                (click)="setAlbumImageClick()"
              >
                <i class="fa fa-book"></i>
                <!-- <span>Albumkép</span> -->
              </a>
            </div>

            <div class="pull-left tm-icon" style="margin-right: 15px;">
              <a
                matTooltip="Profilkép"
                matTooltipPosition="below"
                data-drawer="messages"
                class="drawer-toggle"
                href=""
                (click)="setUserProfilClick()"
              >
                <i class="fa fa-user"></i>
                <!-- <span>Profilkép</span> -->
              </a>
            </div>

            <div class="pull-left tm-icon" style="margin-right: 15px;">
              <a
                matTooltip="Háttérkép"
                matTooltipPosition="below"
                data-drawer="messages"
                class="drawer-toggle"
                href=""
                (click)="setUserBackground()"
              >
                <i class="fa fa-picture-o"></i>
                <!-- <span>Háttérkép</span> -->
              </a>
            </div>

            <!-- 
            <label style="color: burlywood; text-transform: uppercase; margin-right: 5px;">
                Opciók
            </label> 
            -->

            <div class="pull-left tm-icon" style="margin-left: 5px;">
              <a
                matTooltip="Kép törlés"
                matTooltipPosition="below"
                data-drawer="messages"
                class="drawer-toggle"
                href=""
                (click)="
                  delThisImageClick((images?.data)[startIndex].picId, imgNumber)
                "
              >
                <i class="fa fa-trash-o"></i>
                <!-- <span>Kép törlése</span> -->
              </a>
            </div>
            <!--* zoom+ and zoom- *-->
            <div
              matTooltip="Nagyít-kicsinyít"
              matTooltipPosition="below"
              data-drawer="messages"
              class="drawer-toggle"
              href=""
              class="pull-left tm-icon"
              style="margin-left: 5px; cursor: pointer;"
            >
              <a (click)="changePosition()">
                <i id="zoom" class="fa fa-search-plus"></i>
                <!-- <span>Kép méret</span> -->
              </a>
            </div>
            <!-- rotate  -->
            <div
              matTooltip="Forgatás"
              matTooltipPosition="below"
              data-drawer="messages"
              class="drawer-toggle"
              href=""
              class="pull-left tm-icon"
              style="margin-left: 5px; cursor: pointer;"
            >
              <a (click)="rotateImage()">
                <i id="rota" class="fa fa-arrows-rotate"></i>
                <!-- <span>Kép forgatás</span>-->
              </a>
            </div>
            <!-- download image  -->
            <div
              class="pull-left tm-icon"
              style="margin-left: 5px; cursor: pointer;"
            >
              <a
                matTooltip="Letöltés"
                matTooltipPosition="below"
                data-drawer="messages"
                class="drawer-toggle"
                href=""
                (click)="downloadImage((images?.data)[startIndex])"
              >
                <i class="fa fa-download"></i>
                <!-- <span>Letöltés</span> -->
              </a>
            </div>
            <!-- Information  -->
            <div
              matTooltip="Infó"
              matTooltipPosition="below"
              data-drawer="messages"
              class="drawer-toggle"
              href=""
              class="pull-left tm-icon"
              style="margin-left: 5px; cursor: pointer;"
            >
              <a (click)="infoClick()">
                <i id="rota" class="fa fa-info"></i>
                <!-- <span>Információ</span> -->
              </a>
            </div>
          </div>
        </div>

        <div class="media-footer col-md-1">
          <i
            id="fls"
            class="ngx-gallery-icon-content ngx-gallery-fullscreen fa fa-arrows-alt"
            style="
              margin-top: 0px;
              position: fixed;
              right: 35px;
              cursor: pointer;
            "
            (click)="fullScreen()"
          ></i>

          <i
            *ngIf="transNumber == 1"
            class="ngx-gallery-icon-content ngx-gallery-fullscreen fa fa-play"
            style="
              margin-top: 0px;
              position: fixed;
              right: 10px;
              cursor: pointer;
            "
            (click)="playClick(startIndex)"
          ></i>
          <i
            *ngIf="transNumber == 0"
            class="ngx-gallery-icon-content ngx-gallery-fullscreen fa fa-stop"
            style="
              margin-top: 0px;
              position: fixed;
              right: 10px;
              cursor: pointer;
            "
            (click)="playStopClick(startIndex)"
          ></i>
          <i
            class="ngx-gallery-icon-content ngx-gallery-fullscreen fa fa-times-circle"
            style="
              margin-top: 0px;
              position: fixed;
              right: 60px;
              cursor: pointer;
            "
            data-dismiss="modal"
            (click)="closeModalImage()"
          ></i>
        </div>
      </div>

      <div
        class="modal-body"
        style="padding: 0px; z-index: 0; margin: 0px 0px 0px -7px;"
      >
        <div
          class="row"
          style="
            display: flex;
            height: 95%;
            align-content: center;
            justify-content: center;
            margin: -38px;
          "
        >
          <div class="info" *ngIf="onInfo">
            <h4>FUNKCIÓK</h4>
            Teljes képernyő - Shift+Enter
            <br />
            <!-- <i class="fa fa-arrows-alt" style="position: absolute;margin-top: 5px;font-size: 15px;margin-left: 6px;"></i><br> -->
            Auto lejátszás - Felfelé nyíl
            <br />
            <!-- <i class="fa fa-arrow-up" style="position: absolute;margin-top: 5px;font-size: 15px;margin-left: 6px;"></i><br> -->
            Stop - Lefelé nyíl
            <br />
            <!-- <i class="fa fa-arrow-down" style="position: absolute;margin-top: 5px;font-size: 15px;margin-left: 6px;"></i><br> -->
            Lapozás jobbra - Jobbra nyíl
            <br />
            <!-- <i class="fa fa-arrow-right" style="position: absolute;margin-top: 5px;font-size: 15px;margin-left: 6px;"></i><br> -->
            Lapozás balra - Balra nyíl
            <br />
            <!-- <i class="fa fa-arrow-left" style="position: absolute;margin-top: 5px;font-size: 15px;margin-left: 6px;"></i><br> -->
            ESC / ESC 2x - Nagyított kép bezárása
            <br />
            <br />
            <!-- <i class="fa fa-compress" style="position: absolute;margin-top: 5px;font-size: 15px;margin-left: 6px;"></i><br> -->
            <h4>KÉPINFÓ</h4>
            Fájlnév:
            <br />
            {{ (images?.data)[startIndex]?.filename }}&nbsp;
            <br />
            Módosítás dátuma:
            <br />
            {{ (images?.data)[startIndex]?.modifyDate }}&nbsp;&nbsp;
          </div>

          <i
            class="ngx-gallery-icon-content fa fa-arrow-circle-left"
            (click)="previousClick(startIndex)"
            style="left: 30px; cursor: pointer; z-index: 1;"
            [ngClass]="{ inactive: startIndex == 0 }"
          ></i>

          <img
            [src]="(images?.data)[startIndex]?.picture"
            alt="Sample Image Title"
            [@photoState]="position"
            (@photoState.start)="logAnimation($event)"
            (@photoState.done)="logAnimation($event)"
            style="
              display: block;
              margin: 0px;
              object-fit: contain;
              z-index: 0;
            "
          />

          <i
            class="ngx-gallery-icon-content fa fa-arrow-circle-right"
            (click)="nextClick(startIndex)"
            style="right: 40px; cursor: pointer; z-index: 1;"
            [ngClass]="{ inactive: startIndex == images.data.length - 1 }"
          ></i>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            id="modalClose"
            data-dismiss="modal"
            (click)="closeModalImage()"
            hidden
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="emptyImage">
  <label style="margin-left: 50px; font-size: 20px;">Az album üres...</label>
  <br />
  <button class="btn BtnAll" (click)="upload()">Feltöltés</button>
</div>

<div class="multi-spinner-container" *ngIf="!images && !emptyImage">
  <div class="multi-spinner">
    <div class="multi-spinner">
      <div class="multi-spinner">
        <div class="multi-spinner">
          <div class="multi-spinner">
            <div class="multi-spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
