import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import {
  FormsModule,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms'
import { DataService } from '@app/_services/data.service'
import { EncryptService } from '@app/_services/encrypt.service'
import { AuthService } from '@app/_services/auth.service'
import { Router } from '@angular/router'
import * as $ from 'node_modules/jquery'
import { environment } from '@src/environments/environment'
import { FunctionService } from '../_services/function.service'
import { map, Subject, tap } from 'rxjs'
import {
  BacgroundDatas,
  BackgroundImageResponse,
} from '../_definitions/shared-definitions/settings.definitions'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>()
  loadScript() {
    let node = document.createElement('script')
    node.src = 'assets/js/passwordMeter.js'
    node.type = 'text/javascript'
    node.async = true
    node.charset = 'utf-8'
    document.getElementsByTagName('head')[0].appendChild(node)
    document.head.appendChild(node)
  }

  setPasswordGroupForm: UntypedFormGroup
  public emailForm: UntypedFormGroup
  public invalidEmail: boolean
  public resInfo: boolean = false
  private valid: Boolean = true
  private oldpassword: string
  private newpassword: string
  public required: Boolean = false
  public notMatch: Boolean = false
  public pwdSuccess: Boolean = false
  public pwdError: Boolean = false
  public bglist: any
  public currentItem: string
  private clickCount = 0
  public info: string = ''
  public picture: string = ''
  public active: string = ''
  private picId: number
  private deleted: boolean = false
  public filename: string = ''
  private imageUrl: string = ''
  public resEmailInfo: string

  constructor(
    private formBuilder: UntypedFormBuilder,
    private data: DataService,
    public encrypt: EncryptService,
    public authService: AuthService,
    private router: Router,
    public functions: FunctionService,
    private modal: NzModalService,
  ) {}

  ngOnInit() {
    this.setPasswordGroupForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordAgain: ['', Validators.required],
    })
    this.emailForm = this.formBuilder.group({
      email: ['', Validators.pattern(this.functions.EMAIL_REGEXP)],
    })

    this.loadScript()

    this.data.getEmail().subscribe((res) => {
      if (res['status'] === 200) {
        res['email']
          ? this.emailForm.patchValue({ email: res['email'] })
          : this.emailForm.patchValue({ email: '' })
      }
      if (res['status'] === 403) {
        this.resEmailInfo = 'Email lekérése sikertelen!'
        this.resInfo = true
      }
      if (res['status'] === 401) {
        this.authService.logout()
        this.router.navigate(['/login'])
        location.assign('/login')
      }
    })

    this.getAllBackgroundImages()
  }

  get passf() {
    return this.setPasswordGroupForm.controls
  }

  onChangeEmail() {
    if (this.emailForm.valid) {
      this.invalidEmail = false
      this.data.setEmail(this.emailForm.value.email).subscribe((res) => {
        if (res['status'] === 200) {
          this.resEmailInfo = 'Sikeres módosítás!'
          this.resInfo = true
        }
        if (res['status'] === 403) {
          this.resEmailInfo = 'Sikertlen, próbáld később!'
          this.resInfo = true
        }
        if (res['status'] === 401) {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        }
      })
    } else {
      this.resInfo = false
      this.invalidEmail = true
    }
  }

  setUserPassword() {
    if (
      this.passf.newPassword.value === '' ||
      this.passf.newPasswordAgain.value === '' ||
      this.passf.oldPassword.value === ''
    ) {
      this.valid = false
      this.required = true
      this.pwdSuccess = false
      this.pwdError = false
    } else if (
      this.passf.newPassword.value != this.passf.newPasswordAgain.value
    ) {
      this.valid = false
      this.required = false
      this.notMatch = true
      this.pwdSuccess = false
      this.pwdError = false
    } else {
      this.oldpassword = this.encrypt.setHash(this.passf.oldPassword.value)
      this.newpassword = this.encrypt.setHash(this.passf.newPassword.value)
      this.valid = true
      this.required = false
      this.notMatch = false
      this.pwdError = false
    }

    if (this.valid) {
      this.data
        .setPassword(this.oldpassword, this.newpassword)
        .subscribe((data) => {
          if (data['status'] === 200 && data['statusText'] === 'successfully') {
            this.setPasswordGroupForm.reset()
            this.pwdSuccess = true
            this.pwdError = false
            $('.progress-bar').css('width', 0 + '%')
            $('.progress-bar').removeClass('progress-bar-danger')
            $('.progress-bar').removeClass('progress-bar-warning')
            $('.progress-bar').removeClass('progress-bar-success')
            $('.progress-bar').html('')
          } else if (
            data['status'] === 401 &&
            data['statusText'] === 'failed'
          ) {
            this.required = true
          } else if (
            data['status'] === 401 &&
            data['statusText'] === 'authentication_failed'
          ) {
            this.pwdError = true
          } else {
            this.pwdError = true
            this.required = false
          }
        })
    }
  }

  private getAllBackgroundImages(): void {
    this.bglist = []
    this.picture = ''
    this.active = ''
    this.currentItem = ''
    this.info = ''
    this.deleted = false
    this.data
      .getBgPictures()
      .pipe(
        tap((response: BackgroundImageResponse) => {
          if (response.statusText === 'unauthorized') {
            this.authService.logout()
            this.router.navigate(['/login'])
            location.assign('/login')
          }
        }),
        map((response: BackgroundImageResponse) => response.data),
      )
      .subscribe((bgDatas: BacgroundDatas) => {
        if (bgDatas) {
          this.bglist = bgDatas
          for (var pindex = 0; pindex < this.bglist.length; pindex++) {
            if (this.bglist[pindex].active === true) {
              this.picture = this.bglist[pindex].picture
              this.active = this.bglist[pindex].active
              this.currentItem = this.bglist[pindex].bgId
              this.picId = this.bglist[pindex].bgId
              this.filename = this.bglist[pindex].filename
            }
          }
        } else this.info = 'Még nem töltöttél fel saját háttérképet'
      })
  }

  // TODO: SIMPLE AND DOUBLE CLICK EVENTS
  onClickBgItem(item, id, index) {
    // TODO: "this.deleted" a sima klick event ne következzen be.
    if (!this.deleted) {
      this.clickCount++
      setTimeout(() => {
        if (this.clickCount === 1) {
          // single
          this.currentItem = item.bgId
          this.active = item.active
          this.picture = item.picture
          this.filename = item.filename
          this.picId = id
        } else if (this.clickCount === 2) {
          // double
          this.currentItem = item
          this.setActiveBackground(id)
        }
        this.clickCount = 0
      }, 250)
    }
  }

  public deleteThisWallapaper(
    bgId: number,
    filename: string,
    active: boolean,
  ): void {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'HÁTÉRKÉP TÖRLÉS',
      nzContent: 'Biztos hogy törölni szeretnéd ' + filename + ' háttérképet?',
      nzFooter: [
        {
          label: 'Nem',
          // shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen',
          type: 'primary',
          danger: true,
          // loading: false,
          onClick: () => {
            modal.destroy()
            this.deleted = true
            this.data.deleteBgWallpaper(bgId).subscribe((data) => {
              if (data['statusText'] === 'successfully') {
                if (active) location.assign('/settings')
                else this.getAllBackgroundImages()
              }
              if (data['statusText'] === 'unauthorized') {
                this.authService.logout()
                this.router.navigate(['/login'])
                location.assign('/login')
              }
            })
          },
        },
      ],
    })
  }

  private setActiveBackground(bgId?: number): void {
    const backgroundId = bgId ? bgId : null
    this.data
      .setBgActiveImage(backgroundId)
      .subscribe((response: BackgroundImageResponse) => {
        if (response.status === 200 && response.statusText === 'successfully') {
          // TODO: új háttérkép beállítás
          this.data
            .getBgPicture()
            .subscribe((response: BackgroundImageResponse) => {
              if (
                response.statusText === 'successfully' &&
                response?.data != null
              ) {
                this.imageUrl = response.data[0].picture
                $('body').css('background-image', 'url(' + this.imageUrl + ')')
              } else {
                console.warn(
                  'Error: Nem lehet lekérdezni a felhasználó háttérképét. Az alapértelmezett lett beállítva!',
                )
                this.imageUrl = environment.imageUrl
                $('body').css('background-image', 'url(' + this.imageUrl + ')')
              }
            })
          // TODO: lista frissítése
          this.getAllBackgroundImages()
        }
        // TODO: default visszaállítása
        if (response.status === 200 && response.statusText === 'default') {
          this.imageUrl = environment.imageUrl
          $('body').css('background-image', 'url(' + this.imageUrl + ')')
          this.getAllBackgroundImages()
        }
        if (response.statusText === 'unauthorized') {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        }
      })
  }

  onClickUpload() {
    localStorage.setItem('menu', '/uploading')
    location.assign('/uploading')
  }

  setBackgroundImg() {
    if (!this.active) this.setActiveBackground(this.picId)
  }

  defaultBgImage() {
    this.setActiveBackground()
  }
}
