<!-- Play -->
<div
  class="row"
  style="
    max-height: 50%;
    height: 50%;
    width: 100%;
    -webkit-animation: swing-in-left-bck 6s;
  "
>
  <!-- player -->
  <div class="player">
    <div class="player-wrapper">
      <vg-player (onPlayerReady)="onPlayerReady($event)">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
          <vg-play-pause></vg-play-pause>
          <vg-playback-button></vg-playback-button>

          <vg-time-display
            vgProperty="current"
            vgFormat="mm:ss"
          ></vg-time-display>
          <vg-time-display
            vgProperty="total"
            vgFormat="mm:ss"
          ></vg-time-display>

          <vg-mute></vg-mute>
          <vg-volume></vg-volume>

          <vg-fullscreen></vg-fullscreen>
        </vg-controls>

        <video
          #media
          [vgMedia]="media"
          [src]="url"
          id="singleVideo"
          preload="auto"
          type="video/mp4"
          crossorigin
        ></video>
      </vg-player>
    </div>
  </div>

  <!-- playlist -->
  <div class="playlist" *ngIf="emptyPlayList == false">
    <h3>{{ currentFolder }} videók</h3>
    <ul>
      <li
        class="playlist-item"
        *ngFor="let item of playlist; let $index = index"
        (click)="onClickPlaylistItem(item, $index)"
        [class.selected]="item === currentItem"
      >
        {{ item.title }}&nbsp;&nbsp;
        <button
          class="btn BtnAll delBtn"
          type="submit"
          (click)="deleteThisVideo(item)"
        >
          Töröl
        </button>
        &nbsp;&nbsp;
        <img src="assets/img/play-button.svg" style="max-width: 22px;" />
      </li>
    </ul>
  </div>
</div>

<!-- info -->
<div *ngIf="info" style="-webkit-animation: swing-in-top-fwd 4s;">
  <label class="info-message">
    {{ inf_message }}
  </label>
</div>

<!-- folders -->
<div class="row folders">
  <div class="row" *ngIf="emptyGroups === false">
    <div style="-webkit-animation: swing-in-top-fwd 4s;">
      <button
        *ngFor="let data of folders.familyGroup"
        class="folder"
        (click)="familyClick(data)"
        [ngClass]="data === selectedFolder ? 'active-folder' : ''"
      >
        <h4 style="position: absolute; color: white; z-index: 1;">
          {{ data }}
        </h4>
        <img src="assets/img/videos-folder.png" />
      </button>
    </div>
    <hr class="line" *ngIf="familyFolders && friendFolders" />
    <br />
    <div style="-webkit-animation: swing-in-top-fwd 4s;">
      <button
        *ngFor="let data of folders.friendGroup"
        class="folder"
        (click)="friendClick(data)"
        [ngClass]="data === selectedFolder ? 'active-folder' : ''"
      >
        <h4 style="position: absolute; color: white; z-index: 1;">
          {{ data }}
        </h4>
        <img src="assets/img/videos-folder.png" />
      </button>
    </div>
  </div>
</div>
