import { Component, OnInit } from '@angular/core'
import { AuthService } from '@app/_services/auth.service'
import { Router } from '@angular/router'
import * as $ from 'node_modules/jquery'
import { DataService } from '@app/_services/data.service'
import { ViewChild, ElementRef } from '@angular/core'
import { EncryptService } from '@app/_services/encrypt.service'
import { FunctionService } from '../_services/function.service'
import {
  AlbumsData,
  AlbumsResponse,
  GroupData,
  GroupResponse,
  SelectedData,
  VideoFolders,
  VideoFoldersResponse,
  VideoUploadResponse,
} from '../_definitions/uploading.definitions'
import { AppComponent } from '../app.component'
import { catchError, map, tap, throwError } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-uploading',
  templateUrl: './uploading.component.html',
  styleUrls: ['./uploading.component.css'],
})
export class UploadingComponent implements OnInit {
  @ViewChild('folderNameBox') public folderNameBox: ElementRef

  public infoGp: string
  public isBrowser: string
  public validBrowser: Boolean = true
  public ForbiddenFile = false
  public files: Array<any> = []
  public img_urls: Array<any> = []
  public loadImg: Boolean = false
  public upload: Array<any> = []
  public sendImages: Array<any> = []
  public maxSend: number = 5
  public percentage: number = 0
  public uploadProgress: Boolean = false
  public allVideoFile: number
  public successful: boolean = false

  public FileExtension: String
  public imgWH: Array<any> = []

  public Progress: Boolean = false
  public progressId = 0
  public count = 0
  public db = 0
  public alldb = 0

  public ValidUpload: Boolean = false

  public status: String = ''

  public location: Boolean = false
  public videoLocation: Boolean = false

  public groupsList: any[] = []
  public albumList: any[] = []
  public foldersListFr: any[] = []
  public foldersListFa: any[] = []
  public videosList: Array<any> = []
  public isSelectedGroup: Boolean = true
  public create: Boolean = false
  public selectAlbumName: string = ''
  private selectAlbumId: string = ''
  private selectedGroup: string = ''
  public up: Boolean = false
  public info: string = ''
  public message: Boolean = true
  private Choose: Boolean = true
  public ChooseVideo: Boolean = false
  public existingList: any = []
  private selectedFolder: SelectedData
  public chosenFa: Boolean = false
  public chosenFr: Boolean = false
  public upVideo: Boolean = false
  public newFolder: Boolean = false
  private rootFolder: string = ''

  public abortData: Boolean = false
  public backgroundPicture: Boolean = true
  public currentIndex: string
  public loader: Boolean = false
  public isShowHelp: boolean = false

  private video_max_size: number = 2147483648

  private base64abc = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '+',
    '/',
  ]

  constructor(
    public authService: AuthService,
    private router: Router,
    private data: DataService,
    public encrypt: EncryptService,
    public functions: FunctionService,
    private appComponent: AppComponent,
  ) {}

  ngOnInit() {
    this.validToken()
    this.isBrowser = this.functions.getBrowserName()
    if (this.isBrowser === 'firefox' || this.isBrowser === 'ie') {
      this.validBrowser = false
      this.info =
        'A Firefox és IE böngészőben ezek a funkciók nem támogatottak! Használj Chrome, Edge vagy Opera, bőngészőt.'
    }
  }

  public onShowHelpButton(): void {
    this.isShowHelp = this.isShowHelp === true ? false : true
  }

  // --------------------------------------------------------------------- Functions -----------------------------------------------------------------------
  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  public getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
  }

  public clearProgress() {
    $('#progress_bar').css('width', 0 + '%')
    this.Progress = false
  }

  private setPercentage(db, num) {
    this.Progress = true
    var bar = $('#progress_bar')
    const percentage = Math.floor((100 / db) * num)
    $('#progress_percentage').html(percentage + '%')
    bar.css('width', percentage + '%')
    if (percentage === 100) {
      this.Progress = false
    }
  }

  // TODO: base64 konvertáló function, fejrész és záró = jel nélkül
  private bytesToBase64(bytes) {
    let result = '',
      i,
      l = bytes.length
    for (i = 2; i < l; i += 3) {
      result += this.base64abc[bytes[i - 2] >> 2]
      result += this.base64abc[
        ((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)
      ]
      result += this.base64abc[((bytes[i - 1] & 0x0f) << 2) | (bytes[i] >> 6)]
      result += this.base64abc[bytes[i] & 0x3f]
    }
    if (i === l + 1) {
      result += this.base64abc[bytes[i - 2] >> 2]
      result += this.base64abc[(bytes[i - 2] & 0x03) << 4]
      result += '=='
    }
    if (i === l) {
      result += this.base64abc[bytes[i - 2] >> 2]
      result += this.base64abc[
        ((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)
      ]
      result += this.base64abc[(bytes[i - 1] & 0x0f) << 2]
      result += '='
    }
    return result
  }

  private fileSizeInfo(size: number): string {
    return (
      'A fájlok mérete összesen: ' +
      this.functions.round(size / 1024 / 1024, 2) +
      ' Mb, ez nagyobb mint 2 Gb. Egyszerre kevesebb fájlt válasz ki!'
    )
  }

  // TODO: nagyméretű video fájlt kisebb darabokra bontjuk
  private parseFile(file, vDatas) {
    var fileSize = file.size
    // TODO: chunkSize a fájl egy darabja
    var chunkSize = 1024 * 1024 * 6 //* byte x byte x Mb = 15Mb!
    var offset = 0
    var self = this
    var chunkReaderBlock = null
    // TODO: össz csomag db szám meghatározása egy konrét videóra
    let all_Slice = Math.trunc(fileSize / chunkSize) + 1
    this.loader = true
    let current_piece = 0
    var readEventHandler = function (evt) {
      if (evt.target.error == null) {
        current_piece = current_piece + 1
        offset += evt.target.result.byteLength
        var byteArray = new Uint8Array(evt.target.result as ArrayBuffer)
        self.sendVideo(
          self.bytesToBase64(byteArray),
          vDatas,
          all_Slice,
          current_piece,
        )
      } else {
        console.error('Read error: ' + evt.target.error)
        return
      }
      if (offset >= fileSize) {
        return
      }
      chunkReaderBlock(offset, chunkSize, file)
    }
    chunkReaderBlock = function (_offset, length, _file) {
      var r = new FileReader()
      var blob = _file.slice(_offset, length + _offset)
      r.onload = readEventHandler
      r.readAsArrayBuffer(blob)
    }
    chunkReaderBlock(offset, chunkSize, file)
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------- Procedures ----------------------------------------------------------------------

  private validToken() {
    this.data
      .getValidToken(localStorage.getItem('currentUser'))
      .subscribe((data) => {
        if (data == false) {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        } else {
        }
      })
  }

  public onScroll() {
    //TODO: itt még nem szükséges
  }

  private clearUploadEventDatas() {
    this.count = 0
    this.db = 0
    $('#progress_bar').css('width', 0 + '%')
    if (this.Choose == false) {
      $('#lblChosse').removeClass('inactiveChoseFiles')
      $('#lblChosse').addClass('activeChoseFiles')
    } else {
      $('#lblChosse').removeClass('activeChoseFiles')
      $('#lblChosse').addClass('inactiveChoseFiles')
    }
    this.info = ''
    this.Progress = false
    this.ValidUpload = false
    this.upload = []
    this.img_urls = []
    this.files = []
    this.imgWH = []
    this.location = false
    this.existingList = []
    this.percentage = 0
    this.uploadProgress = false
    this.loadImg = false
  }

  public delUploadClick(i) {
    this.upload.splice(i, 1)
    this.img_urls.splice(i, 1)
    this.imgWH.splice(i, 1)
    if (this.upload.length == 0) {
      this.ValidUpload = false
      this.abortData = false
      this.abortLoading()
    }
  }
  // TODO: Csoport választásakor visszaadjuk az albumokat
  public groupSelectClick(selected: SelectedData) {
    this.selectedGroup = selected?.value
    this.up = false
    this.albumList = []
    if (
      selected !== null &&
      selected?.value !== '' &&
      selected?.value !== 'Háttérkép'
    ) {
      this.create = true
      this.isSelectedGroup = true
      this.backgroundPicture = false
      this.data
        .usergroupalbums(this.selectedGroup)
        .subscribe((data: AlbumsResponse) => {
          if (
            data &&
            data.status === 200 &&
            data.statusText === 'successfully'
          ) {
            data.data.forEach((element: AlbumsData, index) => {
              let elements = {
                ...element,
                value: element.albumName,
                id: element.albumId,
              }
              this.albumList.push(elements)
            })
          } else {
            console.warn('ERROR STATUS: ', data)
          }
        })
    }
    if (
      selected !== null &&
      selected?.value !== '' &&
      selected?.value === 'Háttérkép'
    ) {
      this.backgroundPicture = true
      this.isSelectedGroup = false
      this.create = false
      this.albumList = []
      this.up = true
    }
  }

  // TODO: Album kiválasztásakor engedélyezzük a küldést
  public albumSelectClick(selected: SelectedData) {
    if (selected) {
      this.selectAlbumName = selected?.value
      this.selectAlbumId = String(selected?.id)
      this.up = true
    } else {
      this.selectAlbumName = ''
      this.selectAlbumId = ''
      this.up = false
    }
  }

  private clearProgressUpload() {
    this.percentage = 0
    $('#progressBar').css({ width: this.percentage + '%' })
  }

  // TODO: Feltöltés folyamatjelző progress
  private setProgressUpload(uploadLength, sendImages) {
    if (sendImages === 0) sendImages = uploadLength
    this.percentage = this.percentage + (100 / uploadLength) * sendImages
    $('#progressBar').css({ width: this.percentage + '%' })
  }

  sendPictures(sendImages, albumId, uploadLength, call, db) {
    this.location = false
    this.data.setPictures(sendImages, albumId).subscribe(
      (data) => {
        this.alldb = this.alldb + db
        this.setProgressUpload(uploadLength, sendImages.length)
        if (data['status'] === 200 && data['statusText'] === 'unauthorized') {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        } else if (
          data['status'] === 200 &&
          data['statusText'] === 'existingImageNames'
        ) {
          this.setProgressUpload(uploadLength, sendImages.length)
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
          this.info = 'Létező kép(ek)!'
          $('.albumSelect').addClass('existingImages')
          this.successful = true
        } else if (
          call === 0 &&
          data['status'] === 200 &&
          data['statusText'] === 'successfully' &&
          uploadLength > this.alldb
        ) {
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
        } else if (
          call === 0 &&
          data['status'] === 200 &&
          data['statusText'] === 'successfully' &&
          uploadLength === this.alldb
        ) {
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
          if (this.existingList['existingDatas'].length > 0) {
            this.info = 'Létező kép(ek)!'
            $('#progressBar').css({ width: 100 + '%' })
            this.successful = true
          } else {
            this.clearUploadEventDatas()
            this.info = 'A feltöltés sikeres!'
            this.successful = true
          }
          this.abortData = true
        } else if (
          call === 1 &&
          data['status'] === 200 &&
          data['statusText'] === 'successfully' &&
          uploadLength === this.alldb
        ) {
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
          if (this.existingList['existingDatas'].length > 0) {
            this.info = 'Létező kép(ek)!'
            $('#progressBar').css({ width: 100 + '%' })
            this.successful = true
          } else {
            // this.Choose = false
            this.clearUploadEventDatas()
            this.info = 'A feltöltés sikeres!'
            this.successful = true
          }
          this.abortData = true
        } else if (
          data['status'] === 200 &&
          data['statusText'] === 'privatLimit'
        ) {
          this.info = 'Bocs, elérted a PRIVÁT képek max mennyiségét!'
          $('#progressBar').css({ width: 100 + '%' })
        } else if (data['status'] === 500) {
          this.info = 'Bocs, belső hiba történt! Próbáld újra később...'
        }
      },
      (error) => {
        this.info = 'Hiba a feltöltés során, próbáld újra!'
      },
    )
  }

  onJumpToUploadLocation() {
    localStorage.setItem('gname', this.selectedGroup)
    localStorage.setItem('albumName', this.selectAlbumName)
    localStorage.setItem('abid', this.selectAlbumId)
    this.router.navigate(['/images'])
    this.appComponent.HomeClick()
  }

  sendBgPictures(sendImages, uploadLength, call, db) {
    this.data.uploadBgPictures(sendImages).subscribe(
      (data) => {
        this.alldb = this.alldb + db
        if (data['status'] == '200' && data['statusText'] == 'unauthorized') {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        } else if (
          data['status'] == '200' &&
          data['statusText'] == 'existingImageNames'
        ) {
          this.setProgressUpload(uploadLength, sendImages.length)
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
          this.info = 'Létező kép(ek)!'
          $('.albumSelect').addClass('existingImages')
          this.successful = true
        } else if (
          call == 0 &&
          data['status'] == '200' &&
          data['statusText'] == 'successfully' &&
          uploadLength > this.alldb
        ) {
          this.setProgressUpload(uploadLength, sendImages.length)
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
        } else if (
          call == 0 &&
          data['status'] == '200' &&
          data['statusText'] == 'successfully' &&
          uploadLength == this.alldb
        ) {
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
          if (this.existingList['existingDatas'].length > 0) {
            this.info = 'Létező kép(ek)!'
            $('#progressBar').css({ width: 100 + '%' })
            this.successful = true
          } else {
            this.Choose = false
            this.clearUploadEventDatas()
            this.info = 'A feltöltés sikeres!'
          }
          this.abortData = false
        } else if (
          call == 1 &&
          data['status'] == '200' &&
          data['statusText'] == 'successfully' &&
          uploadLength == this.alldb
        ) {
          if (data['existingDatas'].length != 0) {
            for (var e = 0; e < data['existingDatas'].length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                data['existingDatas'][e],
              )
            }
          }
          if (this.existingList['existingDatas'].length > 0) {
            this.info = 'Létező kép(ek)!'
            $('#progressBar').css({ width: 100 + '%' })
            this.successful = true
          } else {
            this.Choose = false
            this.clearUploadEventDatas()
            this.info = 'A feltöltés sikeres!'
          }
          this.abortData = false
        } else if (data['status'] == '200' && data['statusText'] == 'limited') {
          this.info =
            'Bocs, elérted a háttérképek maximális számát! Törölj a listádból, majd próbáld újra.'
          $('#progressBar').css({ width: 100 + '%' })
        } else if (data['status'] == 500) {
          this.info = 'Bocs, belső hiba történt! Próbáld újra később...'
        }
      },
      (error) => {
        console.log('Post Error: ', error)
        this.info = 'Hiba a feltöltés során, próbáld újra!'
      },
    )
  }

  setValidChosenPictures(upload, albumId) {
    this.info =
      'A ' +
      upload.length +
      'db fájl feltöltése elindult, várd meg amíg befejeződik! A fájl(ok) mérete és a hálózat sebességétől függően ez több perc is lehet!'
    this.existingList['existingDatas'] = []
    this.uploadProgress = true
    if (albumId) {
      var db = 0
      for (var fl = 0; fl < upload.length; fl++) {
        this.sendImages[db] = upload[fl]
        db += 1
        if (db === this.maxSend && upload.length > db) {
          this.sendPictures(this.sendImages, albumId, upload.length, 0, db)
          db = 0
          this.sendImages = []
        } else if (db === this.maxSend && upload.length === db) {
          this.sendPictures(this.sendImages, albumId, upload.length, 1, db)
          this.sendImages = []
        } else if (db != this.maxSend && fl === upload.length - 1) {
          this.sendPictures(this.sendImages, albumId, upload.length, 1, db)
          this.sendImages = []
        }
      }
    } else {
      var db = 0
      for (var fl = 0; fl < upload.length; fl++) {
        this.sendImages[db] = upload[fl]
        db += 1
        if (db == this.maxSend && upload.length > db) {
          this.sendBgPictures(this.sendImages, upload.length, 0, db)
          db = 0
          this.sendImages = []
        } else if (db == this.maxSend && upload.length == db) {
          this.sendBgPictures(this.sendImages, upload.length, 1, db)
          this.sendImages = []
        } else if (db != this.maxSend && fl == upload.length - 1) {
          this.sendBgPictures(this.sendImages, upload.length, 1, db)
          this.sendImages = []
        }
      }
    }
    this.clearProgress()
  }

  abortLoading() {
    location.assign('/uploading')
  }

  onClickFileItem(index) {
    this.currentIndex = index
  }

  deleteThisVideo(index) {
    this.videosList.splice(index, 1)
    if (this.videosList.length === 0) {
      this.abortLoading()
      return
    }
    let AllVideoSize = 0
    this.videosList.forEach((element) => {
      AllVideoSize += element?.size
    })

    if (AllVideoSize < this.video_max_size) {
      this.info = ''
      this.infoGp = ''
      this.ValidUpload = true
    } else {
      this.info = this.fileSizeInfo(AllVideoSize)
    }
  }

  SelectFaFolderClick(event: SelectedData) {
    this.rootFolder = 'family'
    this.selectedFolder = event?.value ?? ''
    this.chosenFr = true
    this.chosenFa = false

    this.newFolder = false
    this.upVideo = false

    if (event?.id !== 0) {
      this.upVideo = true
    }
    if (event?.id === 0) {
      this.newFolder = true
    }
    if (event === null) {
      this.rootFolder = ''
      this.chosenFr = false
    }
  }

  SelectFrFolderClick(event: SelectedData) {
    this.rootFolder = 'friends'
    this.selectedFolder = event?.value ?? ''
    this.chosenFa = true
    this.chosenFr = false

    this.newFolder = false
    this.upVideo = false

    if (event?.id !== 0) {
      this.upVideo = true
    }
    if (event?.id === 0) {
      this.newFolder = true
    }
    if (event === null) {
      this.rootFolder = ''
      this.chosenFa = false
    }
  }

  folderNameBoxValue(event) {
    if (event.target.value.length > 0) {
      this.upVideo = true
      this.selectedFolder = this.functions.capitalizeFirstLetter(
        event.target.value,
      )
    } else this.upVideo = false
  }

  sendVideo(file, vDatas, all_Slice, current_piece) {
    this.uploadProgress = true
    this.data.upVideo(file, vDatas, all_Slice, current_piece).subscribe({
      next: (response: VideoUploadResponse) => {
        this.clearProgressUpload()
        this.setProgressUpload(all_Slice, current_piece)
        if (response.status === 200) {
          this.videosList.splice(this.videosList.length - 1, 1)
          this.allVideoFile = this.allVideoFile - 1
          this.info =
            'A feltöltés elindult, várd meg amíg befejeződik! Csomagok: ' +
            response.allSlice +
            '/' +
            response.currentPiece +
            ' elküldve.' +
            ' Fájlok: ' +
            this.allVideoFile.toString()
          // TODO: letező videó info gyűjtése
          if (response.existingDatas.length != 0) {
            for (var e = 0; e < response.existingDatas.length; e++) {
              this.existingList['existingDatas'].splice(
                0,
                0,
                response.existingDatas[e],
              )
            }
          }
          // TODO: sikeres feltőltés, ha a lista üres
          if (
            this.videosList.length < 1 &&
            this.existingList['existingDatas'].length == 0
          ) {
            this.info = 'A feltöltés sikeres!'
            this.restoreDefaultValues()
            this.clearProgressUpload()
          }
          // TODO: sikeres feltőltés, ha a lista üres, de volt létező videó
          if (
            this.videosList.length < 1 &&
            this.existingList['existingDatas'].length > 0
          ) {
            this.restoreDefaultValues()
            this.clearProgressUpload()
            this.info = 'Létező videó(k): '
          }
        }
        if (response.status === 202) {
          this.uploadProgress = true
          this.info =
            'A feltöltés elindult, várd meg amíg befejeződik! Csomagok: ' +
            response.allSlice +
            '/' +
            response.currentPiece +
            ' elküldve.' +
            ' Fájlok: ' +
            this.allVideoFile.toString()
        }
        if (response.status === 403) {
          this.info =
            'Nincs írási engedély! Kérj hozzáférést, ha szeretnél feltölteni.'
          this.uploadProgress = false
          this.clearProgressUpload()
        }
        if (response.status === 401) {
          this.info = 'Lejárt a bejelentkezési időd! Jenetkezz be újra.'
          this.uploadProgress = false
          this.clearProgressUpload()
        }
      },
      error: (error) => {
        console.log('Post Error: ', error)
        this.info = 'Hiba történt a feltöltés során, próbáld újra!'
      },
    })
  }

  private restoreDefaultValues(): void {
    this.newFolder = false
    this.loader = false
    this.videoLocation = false
    this.ValidUpload = false
    this.uploadProgress = false
    this.ChooseVideo = false
    this.groupsList = []
    this.albumList = []
    this.foldersListFa = []
    this.foldersListFr = []
  }

  private setPreviewImage(image, index) {
    var reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = async (e: any) => {
      var _image = new Image();
      // base64 image:
      _image.src = e.target.result
      _image.onload = async () => {
        //this.imgWH.push(_image.width + '*' + _image.height)
        this.files[index].widthAndHeight = _image.width + '*' + _image.height
        //this.img_urls.push(_image.src)
        this.files[index].sizes = (_image.width + '*' + _image.height).split('*', 2)
        this.files[index].imgUrl = _image.src

        this.upload.splice(index, 0, {
          extension: this.getFileExtension(image.name),
          lastModified: image.lastModified,
          name: image.name,
          size: image.size,
          type: image.type,
          sizes: (_image.width + '*' + _image.height).split('*', 2),
          imgUrl: _image.src
        })
      }
    }
  }

  /**
   *  KIVÁLASZTÁS ESEMÉNYEK (KÉP)
   */
  ChooseFileChange(event) {
    $('#ChosseVideoFile').css('display', 'none')
    this.Choose = true
    this.message = false
    this.clearUploadEventDatas()
    let forbidden = []
    this.files = event.target.files
    let forbiddenType = [
      'bz2',
      'gz',
      'tar',
      'tgz',
      'zip',
      'rar',
      'exe',
      'gzip',
      'svg',
      'pdf',
      'doc',
      'txt',
      'docx',
      'msi',
      'xls',
      'xlsx',
      '',
    ] //* tiltott fájlok
    let licensedFileExtension = [
      'jpg',
      'jpeg',
      'png',
      'tiff',
      'gif',
      'bmp',
      'bpg',
      'JPG',
      'JPEG',
      'PNG',
      'TIFF',
      'GIF',
      'BMP',
      'BPG',
    ] //* engedelyezett fajlok
    let maxSize = 5242880 //* byte ~5Mb max méret meghatározás
    this.abortData = true
    console.log('ChooseFileChange', this.files, 'typeof', typeof this.files)
    if (this.files.length > 0) {

      let licensed = true
      this.ForbiddenFile = false

      this.img_urls = []
      this.imgWH = []

      // * A megnyitott fájlok ellenőrzése
      for (var l = 0; l < this.files.length; l++) {
        
        this.FileExtension = this.getFileExtension(this.files[l].name)
        this.files[l].extension = this.FileExtension //*A + fájlkiterjesztés infot hozzáadjuk az eredeti fájladatokhoz
        
        this.setPreviewImage(this.files[l], l);

        this.count = this.files.length;
        this.setPercentage(this.files.length, l);

        //*TILTOTT KITERJESZTÉSEK ELLENŐRZÉSE
        for (var i = 0; i < forbiddenType.length; i++) {      
          if (
            this.files[l].type.indexOf(forbiddenType[i]) > 0 ||
            this.FileExtension === forbiddenType[i] ||
            this.files[l].size > maxSize
          ) {
            this.ForbiddenFile = true
            forbidden.push(l)
          }
        }

        // * ENGEDÉLYEZETT KITERJESZTÉSEK ELLENŐRZÉSE
        licensed = false;
        for (var j = 0; j < licensedFileExtension.length; j++) {
          if (
            this.FileExtension === licensedFileExtension[j]
          ) {
            licensed = true // Engedélyezett fájl
          } 
        }

        if (this.ForbiddenFile === false && licensed) {
          // this.upload.splice(l, 0, {
          //   extension: this.files[l].extension,
          //   lastModified: this.files[l].lastModified,
          //   name: this.files[l].name,
          //   size: this.files[l].size,
          //   type: this.files[l].type,
          // })
          this.clearProgress()
          this.ValidUpload = true
        }
      }
      console.log('tiltott összesen:',forbidden.length, 'this files:', this.files)
      console.log('\n upload fájlok: ', this.upload, 'typeof upload', typeof this.upload)
      if (forbidden.length <= 0) {
        // this.getImgURL(this.files)
        this.loadImg = true
      } else {
        this.info =
          'Tiltott tartalom! A feltölteni kíván fájl vagy lista, tiltott elemet tartalmaz, vagy túl nagy méretű képet! MAX 5mb'
      }
    }
  }
  /**
   *  KIVÁLASZTÁS ESEMÉNYEK (VIDEÓ)
   */
  ChooseVideoFileChange(event) {
    this.existingList['existingDatas'] = []
    this.chosenFa = false
    this.chosenFr = false
    this.newFolder = false
    this.info = ''
    this.abortData = true
    this.message = false
    $('#ChosseImages').css('display', 'none')
    $('#img_urls').css('display', 'none')
    this.files = event.target.files
    let licensedFileExtension = ['mp4']
    let videoUpload = []
    let warn: boolean = false
    let allSize = 0
    if (this.files.length > 0) {
      for (var l = 0; l < this.files.length; l++) {
        this.FileExtension = this.getFileExtension(this.files[l].name)
        this.files[l].extension = this.FileExtension
        allSize = allSize + this.files[l].size
        for (var j = 0; j < licensedFileExtension.length; j++) {
          if (
            this.FileExtension === licensedFileExtension[j] &&
            this.files[l].size < this.video_max_size
          ) {
            videoUpload.push(this.files[l])
          } else {
            this.info =
              'Figyelem: A kiválasztott videó(k) között akad olyan, ami nem mp4 kiterjesztésű, vagy meghaladja a 2Gb méretet. ' +
              'Minden egyéb video formátumot konvertálj mp4-re!'
            warn = true
          }
        }
      }
      if (allSize >= this.video_max_size) {
        this.info = this.fileSizeInfo(allSize)
        warn = true
      }
      this.videosList = videoUpload
      if (this.videosList.length > 0 && allSize < this.video_max_size) {
        this.ValidUpload = true
        this.ChooseVideo = true
      }
      if (warn) {
        let element =
          '<a href="https://video.online-convert.com/convert-to-mp4" target="_blank">' +
          'video.online-convert.com' +
          '</a>' +
          '<br>' +
          '<a href="https://www.onlinefreeconvert.com/mkv-to-mp4.html" target="_blank">' +
          'online convert' +
          '</a>' +
          '<br>' +
          '<a href="https://www.freemake.com/free_video_converter/" target="_blank">' +
          'local converter'
        this.infoGp = element
      }
    }
  }

  /**
   *  FELTŰÖLTÉS MIT - HOVA
   */
  uploadClick() {
    if (!this.ChooseVideo) {
      this.alldb = 0
      this.groupsList = []
      this.albumList = []
      this.data.getUserGroups().subscribe((data: GroupResponse) => {
        if (data.status === 200 && data.statusText === 'successfully') {
          this.groupsList.push({ value: 'Háttérkép', id: 0 })
          data.data.forEach((element: GroupData, index) => {
            let elements = {
              ...element,
              value: element.groupName,
              id: index + 1,
            }
            this.groupsList.push(elements)
          })
        } else if (data.status === 200 && data.statusText === 'unauthorized') {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        } else {
          console.warn('upload status: ', data)
        }
      })
      this.location = true
    } else {
      this.videoLocation = true
      this.data.getVideoFolders().pipe(
          tap((res: VideoFoldersResponse) => {
            if (res?.statusText === 'unauthorized') {
              this.authService.logout()
              this.router.navigate(['/login'])
              location.assign('/login')
            }
          }),
          map((response: VideoFoldersResponse) => response.data),
          catchError((err: HttpErrorResponse) => {
            return throwError(() => new Error())
          }),
        ).subscribe((data: VideoFolders) => {
          if (data) {
            const folders: VideoFolders = data[0]
            const familyGroup = folders.familyGroup
            const friendGroup = folders.friendGroup

            if (familyGroup?.length > 0) {
              this.foldersListFa.push({ value: 'Új mappa', id: 0 })
              familyGroup.forEach((element, index) => {
                let elements = {
                  value: element,
                  id: index + 1,
                }
                this.foldersListFa.push(elements)
              })
            }

            if (friendGroup?.length > 0) {
              this.foldersListFr.push({ value: 'Új mappa', id: 0 })
              friendGroup.forEach((element, index) => {
                let elements = {
                  value: element,
                  id: index + 1,
                }
                this.foldersListFr.push(elements)
              })
            }
          }
        })
    }
  }

  /**
   *  KÜLDÉS ESEMÉNYEK
   */
  send() {
    this.setValidChosenPictures(this.upload, this.selectAlbumId)
  }
  videoToPrepare() {
    this.info = 'A feltöltés elindult, várd meg amíg befejeződik!'
    this.upVideo = false
    this.chosenFa = true
    this.chosenFr = true
    this.newFolder = false
    this.allVideoFile = this.videosList.length
    this.videoLocation = false
    for (var v = 0; v < this.videosList.length; v++) {
      let vDatas = {
        lastModified: this.videosList[v].lastModifiedDate,
        fileName: this.videosList[v].name,
        size: this.videosList[v].size,
        rootFolder: this.rootFolder,
        selectedFolder: this.selectedFolder,
      }
      this.parseFile(this.videosList[v], vDatas)
    }
  }
}
