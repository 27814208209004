import { Injectable } from '@angular/core'
import * as moment from 'node_modules/moment'
import { AuthService } from '@app/_services/auth.service'
import { Router, NavigationStart } from '@angular/router'
import { UntypedFormGroup } from '@angular/forms'
import { NzModalService } from 'ng-zorro-antd/modal'
import { DataService } from './data.service'
import { RefreshTokenResponse } from '../_definitions/function.definitions'
import { NzMessageService } from 'ng-zorro-antd/message'

@Injectable({
  providedIn: 'root',
})
export class FunctionService {
  private logoutWarningTime = 120 // másodperc

  constructor(
    private router: Router,
    public authService: AuthService,
    private modal: NzModalService,
    private data: DataService,
    private message: NzMessageService,
  ) {}

  public round(value, precision) {
    var multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  }

  private pad(n) {
    return n < 10 ? '0' + n : n
  }

  private Timer(seconds) {
    var days = Math.floor(seconds / 24 / 60 / 60)
    var hoursLeft = Math.floor(seconds - days * 86400)
    var hours = Math.floor(hoursLeft / 3600)
    var minutesLeft = Math.floor(hoursLeft - hours * 3600)
    var minutes = Math.floor(minutesLeft / 60)
    var remainingSeconds = seconds % 60
    if (document.getElementById('countdown') !== null)
      document.getElementById('countdown').innerHTML =
        this.pad(hours) +
        ':' +
        this.pad(minutes) +
        ':' +
        this.pad(remainingSeconds)
  }

  public countdownTimer() {
    let currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss') // Aktuális dátum idő
    let validDate = moment(
      localStorage.getItem('tokenValid'),
      'YYYY-MM-DD HH:mm:ss',
    ) // login érvényessége
    let diff_ms = moment(validDate).diff(moment(currentDateTime)) // különbség millisecben

    var d = moment.duration(diff_ms)
    var diff_Time =
      Math.floor(d.asHours()) + moment.utc(diff_ms).format(':mm:ss') // a millisecet óra percben másodpercben
    var tmp = diff_Time.split(':') // daraboljuk
    var Diff_seconds = +tmp[0] * 60 * 60 + +tmp[1] * 60 + +tmp[2] // másodpercben

    var interval = setInterval(() => {
      if (Diff_seconds === 0 || document.getElementById('countdown') === null) {
        clearInterval(interval)
        this.authService.logout()
        location.assign('/login')
      } else {
        Diff_seconds = Diff_seconds - 1
      }
      // const test_time = 60 * 60 + 60 * 52 // 1óra + 60 * perc
      if (Diff_seconds === this.logoutWarningTime) {
        this.getConfirmAutoLogout()
      }
      this.Timer(Diff_seconds)
    }, 1000)
  }

  // TODO: Ezt most nem tudom használni!
  // Meg kell nézni miért is frissítem be az oldalt kilépéskor
  private hasAvailableTimeExpired() {
    const modal = this.modal.create({
      nzTitle: 'FIGYELEM',
      nzContent: 'Az időkorlát lejárt!',
      nzFooter: [
        {
          label: 'OK',
          type: 'primary',
          onClick: () => {
            modal.destroy()
          },
        },
      ],
    })
  }

  private getConfirmAutoLogout() {
    const modal = this.modal.create({
      nzTitle: 'FIGYELEM',
      nzContent:
        'Az időkorlát ' +
        this.logoutWarningTime / 60 +
        ' perc múlva lejár! Szeretnél maradni?',
      nzFooter: [
        {
          label: 'Nem',
          shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen',
          type: 'primary',
          onClick: () => {
            this.data
              .getRefreshToken(localStorage.getItem('currentUser'))
              .subscribe({
                next: (response: RefreshTokenResponse) => {
                  console.log('token refresh response:', response)
                  if (response.status === 200) {
                    localStorage.setItem('tokenValid', response.valid)
                    this.countdownTimer()
                    this.message.success('Az időkorlát frissítése sikeres!')
                  }
                },
                error: (error) => {
                  throw new Error('Hiba a token frissítése közben! ' + error)
                },
              })
            modal.destroy()
          },
        },
      ],
    })
  }

  public getActualSeasones() {
    let month = parseInt(moment().format('M'))
    const spring: any = [3, 4, 5]
    const summer: any = [6, 7, 8]
    const fall: any = [9, 10, 11]
    const winter: any = [12, 1, 2]

    if (spring.includes(month)) return 'spring'
    // tavasz
    else if (summer.includes(month)) return 'summer'
    // nyár
    else if (fall.includes(month)) return 'fall'
    // ősz
    else if (winter.includes(month)) return 'winter'
    // tél
    else return null
  }

  public get_SeasonesBackground_URL() {
    // TODO: environments fájlokba beállítva!
    let Seasones = this.getActualSeasones()
    if (Seasones === 'spring') return 'assets/img/hd/tavasz5.jpg'
    // tavasz
    else if (Seasones === 'summer') return 'assets/img/hd/nyar.jpg'
    // nyár
    else if (Seasones === 'fall') return 'assets/img/hd/osz.jpg'
    // ősz
    else if (Seasones === 'winter') return 'assets/img/hd/tel.jpg'
    // tél
    else return ''
  }

  public getBrowserName() {
    const isOpera = navigator.userAgent.indexOf(' OPR/') >= 0
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge'
      case agent.indexOf('opr') > -1 || isOpera:
        return 'opera'
      case agent.indexOf('chrome') > -1:
        return 'chrome'
      case agent.indexOf('trident') > -1:
        return 'ie'
      case agent.indexOf('firefox') > -1:
        return 'firefox'
      case agent.indexOf('safari') > -1:
        return 'safari'
      default:
        return 'other'
    }
  }

  public dateUTCparser(utcDateTime) {
    if (
      utcDateTime !== undefined &&
      utcDateTime !== null &&
      utcDateTime !== ''
    ) {
      if (utcDateTime.substr(utcDateTime.length - 1, 1) != 'Z') {
        return moment(utcDateTime + 'Z').format('YYYY-MM-DD HH:mm:ss')
      } else return moment(utcDateTime).format('YYYY-MM-DD HH:mm:ss')
    } else return ''
  }

  // Visszaadja, hogy egy FormControl invalid-e onchange event!
  public isFormControlInvalid(
    form: UntypedFormGroup,
    formControlName: string,
    formGroupName?: string,
  ): boolean {
    if (formGroupName) {
      return (
        form.get(formGroupName)['controls'][formControlName] &&
        form.get(formGroupName)['controls'][formControlName].dirty &&
        form.get(formGroupName)['controls'][formControlName].invalid
      )
    }
    return (
      form.controls[formControlName] &&
      form.controls[formControlName].dirty &&
      form.controls[formControlName].invalid
    )
  }

  // Reguláris kifejezés email formátum ellenőrzésére
  public EMAIL_REGEXP: RegExp = new RegExp(
    [
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@',
      '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+',
      '[a-zA-Z]{2,}))$',
    ].join(''),
  )

  // A FormGroup minden FormControl-ját szerkesztettnek jelöli és futtat rajtuk egy validációt
  public markFormGroupTouched(formGroup: UntypedFormGroup) {
    ;(<any>Object)
      .keys(formGroup.controls)
      .map((key) => formGroup.controls[key])
      .forEach((control) => {
        control.markAsTouched()
        control.markAsDirty()
        control.updateValueAndValidity()
        if (control.controls) {
          this.markFormGroupTouched(control)
        }
      })
  }

  public isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0
  }

  // az első karakter legyen nagybetűs
  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}
