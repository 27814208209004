import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Options } from '@src/app/_definitions/shared-definitions/nz-select.definitions'

@Component({
  selector: 'app-nz-select',
  templateUrl: './nz-select.component.html',
  styleUrls: ['./nz-select.component.css'],
})
export class NzSelectComponent {
  @Input() options: Options[]
  @Output() option: EventEmitter<any> = new EventEmitter<any>()
  @Input() placeHolder: string = 'Válassz'
  public selectedOption: any = {}

  public onSelectedClick(selected: any): void {
    if (selected !== null) {
      const selectedOption = {
        id: selected.id,
        value: selected.value,
      }
      this.option.emit(selectedOption)
    } else {
      this.option.emit(this.selectedOption)
    }
  }
}
