import { Injectable } from '@angular/core';
import * as CryptoJS from 'node_modules/crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncryptService {

  constructor() { }

  keys = "@LEDI_2019#pw&.MaxY";

  // set password hash
  setHash(value){
    var key = CryptoJS.enc.Utf8.parse(this.keys);
    var pass = CryptoJS.HmacSHA512(CryptoJS.enc.Utf8.parse(value.toString()), key).toString();
    return pass
  }

  // The set method is use for encrypt the value.
  set(value){
    var key = CryptoJS.enc.Utf8.parse(this.keys);
    var iv = CryptoJS.enc.Utf8.parse(this.keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128, //keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.ECB,//mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  // The get method is use for decrypt the value.
  get(value){
    var key = CryptoJS.enc.Utf8.parse(this.keys);
    var iv = CryptoJS.enc.Utf8.parse(this.keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128,
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  setBase64(value){
    return btoa(value);
  }
  getBase64(value){
    return atob(value);
  }

}
