import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router'
import { forkJoin, Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { DataService } from '../_services/data.service'

@Injectable()
export class HomeResolver implements Resolve<any> {
  constructor(private router: Router, public data: DataService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return { test: 'ok' }
    // return forkJoin([
    //   // this.data.getGroupsOfLoggedinUser().pipe(
    //   //   catchError((err) => {
    //   //     // this.router.navigate(['/', '404']);
    //   //     console.error('Error: ', err)
    //   //     return throwError(err)
    //   //   }),
    //   // ),
    //   // this.data.getCategorizedGroups().pipe(
    //   //   catchError((err) => {
    //   //     // this.router.navigate(['/', '404']);
    //   //     console.error('Error: ', err)
    //   //     return throwError(err)
    //   //   }),
    //   // ),
    // ])
  }
}
