import { Component, OnInit } from '@angular/core'
import { AuthService } from '@app/_services/auth.service'
import { Router, NavigationStart } from '@angular/router'
import { DataService } from '@app/_services/data.service'
import * as $ from 'node_modules/jquery'
import { HostListener } from '@angular/core'
import { FunctionService } from '@app/_services/function.service'
import { environment } from '@src/environments/environment'
import { hu_HU, NzI18nService } from 'ng-zorro-antd/i18n'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
@HostListener('window:resize', ['$event'])
export class AppComponent implements OnInit {
  currentUrl: string = '/home'
  title = 'SzIEra-Media'
  currentUser: string = localStorage.getItem('currentUser')
  picId: string = localStorage.getItem('picId')
  profil_img: object
  out: any
  show: Boolean = true
  scrHeight: any
  scrWidth: any

  constructor(
    private router: Router,
    public authService: AuthService,
    private data: DataService,
    public functions: FunctionService,
    private i18n: NzI18nService,
  ) {
    this.i18n.setLocale(hu_HU) // TODO: ng-zorro nyelvi beállítása
    this.getScreenSize()
  }

  ngOnInit() {
    if (
      localStorage.getItem('currentUser') !== '' &&
      localStorage.getItem('currentUser') !== null
    ) {
      this.show = true
      this.currentUrl = localStorage.getItem('menu')
      this.data.getImage(this.picId).subscribe(
        (data) => {
          if (data['status'] === 200 && data['data']?.length > 0)
            this.profil_img = data['data'][0].picture
        },
        (error) => {
          console.log('Post error: ', error)
        },
      )

      this.setLoginedUserBackgroundPicture()
    } else {
      this.show = false
    }
  }

  getScreenSize() {
    this.scrHeight = window.innerHeight
    this.scrWidth = window.innerWidth
  }

  setDisplayMenu() {
    if (this.scrWidth < 480) {
      let $html = $('html')
      let $side = $('#sidebar')
      if ($html.hasClass('menu-active')) {
        $html.removeClass()
      } else if ($html.hasClass('')) {
        $html.addClass('menu-active')
      }
      if ($side.hasClass('toggled')) {
        $side.removeClass()
      } else if ($side.hasClass('')) {
        $side.addClass('toggled')
      }
    }
  }

  setLoginedUserBackgroundPicture() {
    let imageUrl = ''
    this.data.getBgPicture().subscribe(
      (data) => {
        if (data['statusText'] == 'successfully' && data['data'].length > 0) {
          imageUrl = data['data'][0].picture
          $('body').css('background-image', 'url(' + imageUrl + ')')
        }
        if (data['statusText'] == 'successfully' && data['data'].length == 0) {
          imageUrl = environment.imageUrl
          $('body').css('background-image', 'url(' + imageUrl + ')')
        }
        if (data['statusText'] == 'unauthorized') {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        }
      },
      (error) => {
        console.log('Post error: ', error)
        console.warn(
          'Error: Nem lehet lekérdezni a felhasználó háttérképét. Az alapértelmezett lett beállítva!',
        )
        imageUrl = 'assets/img/hd/buza.jpg'
        $('body').css('background-image', 'url(' + imageUrl + ')')
      },
    )
  }

  logout() {
    if (
      localStorage.getItem('token') != '' &&
      localStorage.getItem('token') != null
    ) {
      this.data.getLogout().subscribe({
        next: (data) => {
          this.out = data
          if (this.out['statusText'] == 'success') {
            this.show = false
            this.currentUser = ''
            this.authService.logout()
            this.router.navigate(['/login'])
          }
          if (this.out['statusText'] == 'invalid token') {
            this.show = false
            this.currentUser = ''
            this.authService.logout()
            this.router.navigate(['/login'])
          }
        },
        error: (error) => {
          throw new Error('Post error: ' + error)
        },
      })
    }
  }

  settings() {
    this.currentUrl = '/settings'
    this.router.navigate([this.currentUrl])
    this.setDisplayMenu()
  }

  HomeClick() {
    this.currentUrl = '/home'
    localStorage.setItem('menu', this.currentUrl)
    this.setDisplayMenu()
  }

  UploadingClick() {
    this.currentUrl = '/uploading'
    localStorage.setItem('menu', this.currentUrl)
    this.setDisplayMenu()
  }

  VideosClick() {
    this.currentUrl = '/videos'
    localStorage.setItem('menu', this.currentUrl)
    this.setDisplayMenu()
  }
}
