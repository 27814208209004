//Default
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
//My services
import { AuthGuard } from '@app/_services/auth.guard'
//Outher components
import { LoginComponent } from './login/login.component'
//Menu components
import { HomeComponent } from './home/home.component'
import { SettingsComponent } from './settings/settings.component'
import { AlbumsComponent } from './albums/albums.component'
import { ImagesComponent } from './images/images.component'
import { UploadingComponent } from './uploading/uploading.component'
import { VideosComponent } from './videos/videos.component'
import { HomeResolver } from './_resorvers/home.resorver'

const routes: Routes = [
  { path: '', component: LoginComponent, data: { title: 'Login' } },

  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'home',
    component: HomeComponent,
    resolve: {
      groups: HomeResolver,
    },
    data: { title: 'Home' },
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Settings' },
    canActivate: [AuthGuard],
  },
  {
    path: 'albums',
    component: AlbumsComponent,
    data: { title: 'Albums' },
    canActivate: [AuthGuard],
  },
  {
    path: 'images',
    component: ImagesComponent,
    data: { title: 'Images' },
    canActivate: [AuthGuard],
  },
  {
    path: 'uploading',
    component: UploadingComponent,
    data: { title: 'Uploading' },
    canActivate: [AuthGuard],
  },
  {
    path: 'videos',
    component: VideosComponent,
    data: { title: 'Videos' },
    canActivate: [AuthGuard],
  },
]

@NgModule({
  //RouterModule.forRoot( routes, { enableTracing: false } ) // <-- debugging purposes only!!! Csak hibakereséshez
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
