// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as moment from 'node_modules/moment'

//! API URL:
// url = 'http://20.14.4.112:5500/' // -- local teszt
// const test_url = 'https://sziera.ddns.net:5500/' // -- teszt
const test_url = 'http://sziera.ddns.net:5500/' // -- teszt
// url = 'https://sziera.ddns.net:5500/' // -- teszt HTTPS ng serve —-ssl true
// url = 'http://sziera.ddns.net/api/' // -- éles
const api_url = 'https://sziera.ddns.net/api/' // -- éles HTTPS

let month = parseInt(moment().format('M'))
const spring: any = [3, 4, 5]
const summer: any = [6, 7, 8]
const fall: any = [9, 10, 11]
const winter: any = [12, 1, 2]
let IMAGE_URL: string
if (spring.includes(month)) IMAGE_URL = 'assets/img/hd/tavasz5.jpg'
// tavasz
else if (summer.includes(month)) IMAGE_URL = 'assets/img/hd/nyar.jpg'
// nyár
else if (fall.includes(month)) IMAGE_URL = 'assets/img/hd/osz2.jpg'
// ősz
else if (winter.includes(month)) IMAGE_URL = 'assets/img/hd/tel.jpg'
// tél
else IMAGE_URL = ''

export const environment = {
  production: true,
  imageUrl: IMAGE_URL,
  testApiURL: test_url,
  apiUrl: api_url,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
