import { Component, OnInit } from '@angular/core'
import { AuthService } from '@app/_services/auth.service'
import { Router } from '@angular/router'
import { DataService } from '@app/_services/data.service'
import { ViewChild, ElementRef } from '@angular/core'
import * as $ from 'node_modules/jquery'
import { DeleteAlbumResponse } from '../_definitions/albums.definitions'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { FunctionService } from '../_services/function.service'

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css'],
})
export class AlbumsComponent implements OnInit {
  @ViewChild('albumNameBox') public albumNameBox: ElementRef
  @ViewChild('descriptionBox') public descriptionBox: ElementRef

  constructor(
    public authService: AuthService,
    private router: Router,
    private data: DataService,
    private message: NzMessageService,
    private modal: NzModalService,
    private functionService: FunctionService,
  ) {
    var shelf = this
    $(document)
      .keydown(function (event) {
        if (event.keyCode == 44) {
          //* printScreen
          //* OS KEZELI
        }
      })
      .keyup(function (event) {
        //* AltGr bill code combo level 2
        if (event.keyCode == 44) {
          //* printScreen
          shelf.copyToClipboard()
          var attr = document
            .getElementById('photo-gallery-alt')
            .getAttribute('class')
          // document.getElementById('psErr').getAttribute('href');
          // shelf.authService.logout();
          // shelf.router.navigate(['/login']);
          // location.assign("/login");
        }
      })
  }

  public albums: any = []
  public newAlbum: boolean = false
  public up: boolean = false
  private albumName = ''
  private groupId = ''
  private descriptation = ''
  public delAlbums: boolean = false
  public delIdList: any = []
  public emptyAlbum: boolean = false
  public info: string = ''
  public OnSpinner: boolean = false
  public groupName: string

  ngOnInit() {
    this.data
      .getValidToken(localStorage.getItem('currentUser'))
      .subscribe((data) => {
        if (data == false) {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        }
      })
    if (localStorage.getItem('gname') != '') {
      this.groupName = localStorage.getItem('gname')
      this.getAlbums(localStorage.getItem('gname'))
    }
    localStorage.removeItem('abid')
    localStorage.removeItem('albumName')
  }

  //*--------------------------------------------------------------------- Functions -----------------------------------------------------------------------
  public isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0
  }

  private copyToClipboard() {
    var aux = document.createElement('input')
    aux.setAttribute(
      'value',
      'Kérlek ne használd ezen az oldalon a PrtScr funkciót!',
    )
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    //alert("A nyomtatási képernyő letiltva!");
    //$("#onPS").click();
    $(window)
      .focus(function () {
        $('#onPS').show()
      })
      .blur(function () {
        $('#onPS').hide()
      })
  }
  PSERR() {
    //console.log("PS")
  }

  //*-------------------------------------------------------------------------------------------------------------------------------------------------------
  //*--------------------------------------------------------------------- Procedures ----------------------------------------------------------------------

  onScroll() {
    //* on scroll... itt még visszajön az összes adat, ezért nem kell végtelen görgetés...
  }

  groupSelected(e) {
    this.delIdList = []
    var deleteIdList: any = document.getElementsByClassName('inp')
    for (let item of deleteIdList) {
      if (item.checked) {
        this.delIdList.splice(0, 0, item.id)
      }
    }
  }

  chooseAlbumItems() {
    this.delAlbums = true
    this.delIdList = []
  }
  deleteAlbumsCancelClick() {
    this.delAlbums = false
    this.delIdList = []
    this.info = ''
  }
  public onDeleteAlbumButtonClick(): void {
    if (this.delIdList?.length > 0) {
      const modal: NzModalRef = this.modal.create({
        nzTitle: 'ALBUM TÖRLÉS',
        nzContent: 'Biztos hogy törölni szeretnéd a kijelölt albumokat?',
        nzFooter: [
          {
            label: 'Nem',
            // shape: 'round',
            onClick: () => {
              modal.destroy()
            },
          },
          {
            label: 'Igen',
            type: 'primary',
            danger: true,
            // loading: false,
            onClick: () => {
              modal.destroy()
              this.onDeleteAlbum()
            },
          },
        ],
      })
    }
  }

  private onDeleteAlbum(): void {
    this.data
      .deletegroupalbums(this.delIdList)
      .subscribe((res: DeleteAlbumResponse) => {
        if (res?.NoPermission?.length > 0) {
          let albums = ''
          res.NoPermission.forEach((element) => {
            albums += element + ', '
          })
          const messageContent =
            'A következő albumok nem törölhetők: ' +
            albums +
            ' Nem te hoztad létre! ' +
            ' Ha a kijelöltek között volt olyan, amit te hoztál létre, akkor az törölve. '
          const modal: NzModalRef = this.modal.create({
            nzTitle: 'TÖRLÉS INFORMÁCIÓ',
            nzContent: messageContent,
            nzFooter: [
              {
                label: 'Értem',
                type: 'primary',
                danger: false,
                onClick: () => {
                  modal.destroy()
                },
              },
            ],
          })
        } else {
          this.message.create(
            'success',
            'Az album(ok) törlése sikeresen végrehajtva!',
          )
        }
        this.getAlbums(localStorage.getItem('gname'))
      })
  }

  groupClick() {
    this.router.navigate(['/home'])
  }

  getAlbums(gname) {
    this.OnSpinner = true
    this.data.getGroupAlbums(gname).subscribe((data) => {
      if (
        data &&
        data['status'] == '200' &&
        data['statusText'] == 'successfully'
      ) {
        if (this.isEmptyObject(data['data']) == true) {
          this.emptyAlbum = true
          this.albums = data
        } else {
          this.emptyAlbum = false
          this.albums = data
        }
        //* alap beallitas:
        this.delAlbums = false
        this.delIdList = []
      } else if (
        data &&
        data['status'] == '200' &&
        data['statusText'] == 'unauthorized'
      ) {
        this.authService.logout()
        this.router.navigate(['/login'])
        location.assign('/login')
      } else {
        this.albums = []
        this.emptyAlbum = true
      }
      this.OnSpinner = false
    })
  }

  openAlbum(albumId, albumName) {
    localStorage.setItem('albumName', albumName)
    localStorage.setItem('abid', albumId)
    this.router.navigate(['/images'])
  }

  public albumNameBoxValue(event): void {
    this.albumNameBox.nativeElement.value = this.functionService.capitalizeFirstLetter(
      event.target.value,
    )
    this.checkingOfMandatoryData()
  }

  private checkingOfMandatoryData(): void {
    if (this.albumNameBox.nativeElement.value !== '') {
      this.up = true
    } else {
      this.up = false
    }
  }

  newAlbumClick() {
    this.newAlbum = true
    this.info = ''
  }

  newAlbumCancelClick() {
    this.newAlbum = false
    this.info = ''
  }

  createAlbum() {
    if (this.albumNameBox.nativeElement.value != '') {
      this.albumName = this.albumNameBox.nativeElement.value
      this.groupId = localStorage.getItem('gid')
      this.descriptation = this.descriptionBox.nativeElement.value
      if (
        this.albumName != '' &&
        this.groupId != '' &&
        this.groupId != undefined
      ) {
        this.data
          .setnewalbums(this.albumName, this.groupId, this.descriptation)
          .subscribe((data) => {
            if (
              data &&
              data['status'] == 200 &&
              data['statusText'] == 'successfully'
            ) {
              if (localStorage.getItem('gname') != '') {
                this.getAlbums(localStorage.getItem('gname'))
                this.newAlbum = false
              }
            }
            if (data && data['status'] == 403) {
              this.info = 'A művelethez nincs megfelelő engedély beállítva!'
            }
            if (
              data &&
              data['status'] == 200 &&
              data['statusText'] == 'unauthorized'
            ) {
              this.authService.logout()
              this.router.navigate(['/login'])
              location.assign('/login')
            }
          })
      }
    }
  }
}
