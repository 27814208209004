<h3 class="page-title" style="-webkit-animation: swing-in-top-fwd 2s;">
  BEÁLLÍTÁSOK
</h3>

<hr class="whiter" />

<div class="block-area" style="-webkit-animation: text-focus-in 4s;">
  <div class="row headerContainer">
    <div class="changeEmailMessage">
      <div class="row">
        <label class="textCenter emailTitle"><b>E-MAIL Beállítás</b></label>
      </div>
      <div class="row">
        <label class="emailInfo">
          A rendszer erre az e-mail címre küldi az új feltöltésre vonatkozó
          értesítőket!
          <br />
          Ha eltávolítod, akkor nyilván nem fogsz kapni infót, ha valaki új
          videót vagy képet töltött fel! Az itt megadott e-mail címnek semmilyen
          más szerepe nincs! Elfelejtett jelszó visszaállítást egyenlőre nem
          tervezek. :)
        </label>
      </div>
      <div class="row emailForm">
        <form [formGroup]="emailForm" (ngSubmit)="onChangeEmail()">
          <div class="form-group">
            <label class="resInfo" *ngIf="resInfo">{{ resEmailInfo }}</label>
            <input
              type="email"
              class="textInput"
              formControlName="email"
              placeholder="E-mail"
            />
          </div>
          <div class="email-error">
            <p *ngIf="invalidEmail">Nem megfelelő formátum!</p>
          </div>
          <button class="btn BtnAll" type="submit">Módosít</button>
        </form>
      </div>
    </div>

    <div class="changePasswordBlock">
      <div *ngIf="required" class="help-block">
        <label style="font-weight: bold;">Hibás vagy hiányzó adat!</label>
      </div>
      <div *ngIf="notMatch" class="help-block">
        <label style="font-weight: bold;">Az új jelszó nem egyezik!</label>
      </div>
      <div *ngIf="pwdSuccess" class="pwdSuccess">
        <label style="font-weight: bold;">A jelszó módosítás sikeres!</label>
      </div>
      <div *ngIf="pwdError" class="help-block">
        <label style="font-weight: bold;">
          Váratlan hiba a mentés közben, a jelszó nem változott!
        </label>
      </div>

      <label class="textCenter"><b>JELSZÓ MÓDOSÍTÁS</b></label>

      <form [formGroup]="setPasswordGroupForm" (ngSubmit)="setUserPassword()">
        <div>
          <input
            type="password"
            class="textInput"
            formControlName="oldPassword"
            placeholder="Régi jelszó"
          />
        </div>

        <div class="form-group">
          <input
            type="password"
            class="form-control"
            formControlName="newPassword"
            id="InputPassword"
            class="textInput"
            placeholder="Új jelszó"
          />
          <br />
          <div class="progress progBar">
            <div class="progress-bar"></div>
          </div>
        </div>

        <input
          type="password"
          class="textInput"
          formControlName="newPasswordAgain"
          placeholder="Új jelszó ismét"
        />
        <br />
        <button class="btn BtnAll" type="submit">Mentés</button>
      </form>
    </div>
  </div>
  <br />
  <hr
    style="
      padding: 8px 15px 9px;
      font-size: 15px;
      border-bottom: 0;
      border-top: 1px solid #fff;
      margin: 0;
      -webkit-animation: swing-in-top-fwd 7s;
    "
  />
  <br />
  <div class="row">
    <div style="margin: auto;">
      <label class="textCenter"><b>HÁTTÉRBEÁLLÍTÁS</b></label>
    </div>
  </div>

  <div class="row">
    <div
      *ngIf="info"
      class="infoGroup"
      style="-webkit-animation: swing-in-top-fwd 4s;"
    >
      <label style="font-size: 1.2rem;">{{ info }}</label>
      <button class="btn BtnAll" type="submit" (click)="onClickUpload()">
        Feltöltés
      </button>
    </div>
  </div>

  <div class="row mobil-content">
    <div class="bglist" *ngIf="bglist">
      <ul style="margin-left: -25px;">
        <li
          class="bglist-item"
          [ngClass]="{ active: item.active === true }"
          *ngFor="let item of bglist; let $index = index"
          (click)="onClickBgItem(item, item.bgId, $index)"
          [class.selected]="item.bgId === currentItem"
        >
          <label class="col-md-5 filename text-content">
            {{ item.filename }}
          </label>
          <label class="col-md-3 text-content hidden-on-mobile">
            {{ item.width }}*{{ item.height }}
          </label>
          <label class="col-md-3 text-content hidden-on-mobile">
            {{ item.size / 1024 / 1024 | number }} Mb
          </label>

          <button
            class="btn BtnAll delBtn col-md-1"
            type="submit"
            (click)="
              deleteThisWallapaper(item.bgId, item.filename, item.active)
            "
          >
            Töröl
          </button>
        </li>
      </ul>
    </div>
    <!-- *ngIf="!info && picture != ''" -->
    <div class="bgPicture">
      <div class="gallery-item">
        <div class="groups_item" *ngIf="picture != ''">
          <div class="groups-img-block">
            <img class="groups_img" src="{{ picture }}" alt="válassz képet" />
            <br />
            <small style="font-size: 9px; font-style: italic; color: blue;">
              {{ filename }}
            </small>
          </div>

          <div class="groups_info">
            <strong
              style="text-transform: uppercase; font-size: 25px;"
              *ngIf="active"
            >
              Aktív
            </strong>
            <button
              class="btn BtnAll"
              (click)="setBackgroundImg()"
              *ngIf="!active"
            >
              Beállítom
            </button>
            <!-- <small
              style="
                font-style: italic;
                font-size: 25px;
                color: blue;
                cursor: pointer;
              "
              (click)="setBackgroundImg()"
              *ngIf="!active"
            >
              Beállítom
            </small> -->
          </div>
          <button class="btn BtnAll" type="submit" (click)="defaultBgImage()">
            Alapértelmezett
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
