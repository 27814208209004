<h3 class="page-title">
  <div class="title-back">
    <a (click)="groupClick()">CSOPORTOK</a>
  </div>
  <div class="title-separator">/</div>
  <div class="title">
    ALBUMOK
  </div>
</h3>

<div class="groupName">{{ groupName }}</div>
<!-- <hr class="whiter" /> -->
<br />
<div class="row btnLine" style="-webkit-animation: swing-in-top-fwd 4s;">
  <button class="btn BtnAll" (click)="newAlbumClick()" *ngIf="!newAlbum">
    Új album
  </button>
  <button
    class="btn BtnAll"
    (click)="chooseAlbumItems()"
    *ngIf="!delAlbums && !newAlbum && !emptyAlbum"
  >
    Kijelölés
  </button>

  <button class="btn BtnAll" (click)="newAlbumCancelClick()" *ngIf="newAlbum">
    Mégsem
  </button>
  <button
    class="btn BtnAll"
    (click)="deleteAlbumsCancelClick()"
    *ngIf="delAlbums"
  >
    Mégsem
  </button>
  <button
    class="btn BtnAll"
    (click)="onDeleteAlbumButtonClick()"
    *ngIf="delAlbums && delIdList['length'] > 0"
  >
    Töröl
  </button>
</div>

<div
  class="row"
  class="albumSelect"
  style="-webkit-animation: swing-in-top-fwd 4s;"
  *ngIf="newAlbum"
>
  <input
    type="text"
    class="input-focused word"
    placeholder="Album név"
    maxlength="20"
    #albumNameBox
    (keyup)="albumNameBoxValue($event)"
  />
  <input
    type="text"
    class="input-focused words"
    placeholder="Rövid leírás (opcionális)"
    maxlength="25"
    #descriptionBox
  />

  <button class="btn saveBtn" (click)="createAlbum()" [disabled]="!up">
    Mehet
  </button>
</div>

<div
  *ngIf="info"
  class="infoGroup"
  style="-webkit-animation: swing-in-top-fwd 4s;"
>
  <label style="font-size: 1rem;">{{ info }}</label>
</div>

<div *ngIf="emptyAlbum" style="-webkit-animation: swing-in-top-fwd 10s;">
  <label
    style="
      margin-left: 16px;
      font-size: 15px;
      color: #f8f9fa;
      background-color: #0000008f;
      padding: 4px;
    "
  >
    Még senki nem hozott létre albumot ebben a csoportban...
  </label>
</div>

<div class="multi-spinner-container" *ngIf="OnSpinner" style="z-index: 1;">
  <div class="multi-spinner">
    <div class="multi-spinner">
      <div class="multi-spinner">
        <div class="multi-spinner">
          <div class="multi-spinner">
            <div class="multi-spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="photo-gallery-alt"
  class="row groups search-results"
  *ngIf="albums"
  style="
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #fda12b91 #4f4f5abd;
  "
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
>
  <div
    class="gallery-item"
    *ngFor="let data of albums.data"
    style="-webkit-animation: swing-in-top-fwd 2s;"
  >
    <div style="width: 100%; height: 100%;">
      <div class="groups_item" style="text-align: center;">
        <label class="container" *ngIf="delAlbums">
          <input
            class="inp"
            type="checkbox"
            data-md-icheck
            [id]="data.albumId"
            (change)="groupSelected($event)"
          />
          <span class="checkmark"></span>
        </label>
        <a
          style="cursor: pointer;"
          (click)="openAlbum(data.albumId, data.albumName)"
        >
          <img
            class="groups_img"
            [src]="data.src"
            alt="image not found"
            *ngIf="data.src"
          />
          <img
            class="groups_img"
            src="assets/img/AlbumIMG220.png"
            alt="image not found"
            *ngIf="!data.src"
          />
        </a>
        <div class="groups_info">
          <a
            style="cursor: pointer;"
            (click)="openAlbum(data.albumId, data.albumName)"
          >
            <strong style="text-transform: uppercase;">
              {{ data.albumName }}
            </strong>
            <br />
          </a>
          <small style="font-size: 14px; font-style: italic; color: linen;">
            {{ data.descriptation }}
          </small>
          <br />
          <small
            style="font-size: 14px; font-style: italic; color: sandybrown;"
          >
            Létrehozta: {{ data.createdBy }}
          </small>
        </div>
      </div>
    </div>
  </div>
</div>
