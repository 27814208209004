<!-- Fejresz -->
<app-headbar *ngIf="show"></app-headbar>
      
<div id="container" >    
            
<!-- Menuk -->
<aside *ngIf="show" id="sidebar" class="" > 
<!-- Itt a class=toggled és az index html class= menu-active akkor fordítva működik, ha ezt a kettőt eltávolítod akkor default megjelenik-->

    <div *ngIf="show" style="position: fixed; margin-left: 45px; width: 188px; max-height: 400px;">
        <!-- Profile Menu -->
        <div class="text-center s-widget m-b-25 dropdown" id="profile-menu">
            <a href="" data-toggle="dropdown"> 
                <img  class="profile-pic animated" [src]="profil_img" alt="profile-page" *ngIf="profil_img"> 
                <img  class="profile-pic animated" src="assets/img/user.png" alt="profile-page" *ngIf="!profil_img"> 
            </a> 

            <h5 *ngIf="show">{{currentUser}}</h5> 

            <a *ngIf="show" (click)="settings()" class="pointer" ><h6><strong>Beállítások</strong></h6> </a>                              
            <a *ngIf="show" (click)="logout()" class="pointer"><h6><strong>Kilépés</strong></h6></a>                                               
        </div>
    </div>

    <!-- Sidebar Menu -->
    <ul *ngIf="show" class="list-unstyled side-menu" style="position: absolute;">

        <li [ngClass]="{'active': currentUrl === '/home'}">
            <a class="sa-side-home" routerLink="home" (click)="HomeClick()">
                <span class="menu-item">Csoportok</span>
            </a> 
        </li>  
        
        <li [ngClass]="{'active': currentUrl === '/uploading'}">
            <a class="sa-side-folder" routerLink="uploading" (click)="UploadingClick()">
                <span class="menu-item">Feltöltés</span>
            </a> 
        </li>  

        <li [ngClass]="{'active': currentUrl === '/videos'}">
        <a class="sa-side-video" routerLink="videos" (click)="VideosClick()">
            <span class="menu-item">Videók</span>
        </a> 
        </li> 

    </ul>

</aside>         

<div id="content"> 

    <hr class="whiter" /> 

    <router-outlet></router-outlet> 

</div>

</div>