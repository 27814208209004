import { Component, OnInit, Inject } from '@angular/core'
import * as $ from 'node_modules/jquery'
import { DOCUMENT } from '@angular/common'
import { FunctionService } from '../_services/function.service'

@Component({
  selector: 'app-headbar',
  templateUrl: './headbar.component.html',
  styleUrls: ['./headbar.component.css'],
})
export class HeadbarComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT)
    private document: any,
    public functions: FunctionService,
  ) {
    /* --------------------------------------------------------
        Date Time Widget
    -----------------------------------------------------------*/
    ;(function () {
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      var dayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]

      // Create a newDate() object
      var newDate = new Date()

      // Extract the current date from Date object
      newDate.setDate(newDate.getDate())

      // Output the day, date, month and year
      $('#date').html(
        dayNames[newDate.getDay()] +
          ' ' +
          newDate.getDate() +
          ' ' +
          monthNames[newDate.getMonth()] +
          ' ' +
          newDate.getFullYear(),
      )

      setInterval(function () {
        // Create a newDate() object and extract the seconds of the current time on the visitor's
        var seconds = new Date().getSeconds()

        // Add a leading zero to seconds value
        $('#sec').html((seconds < 10 ? '0' : '') + seconds)
      }, 1000)

      setInterval(function () {
        // Create a newDate() object and extract the minutes of the current time on the visitor's
        var minutes = new Date().getMinutes()

        // Add a leading zero to the minutes value
        $('#min').html((minutes < 10 ? '0' : '') + minutes)
      }, 1000)

      setInterval(function () {
        // Create a newDate() object and extract the hours of the current time on the visitor's
        var hours = new Date().getHours()

        // Add a leading zero to the hours value
        $('#hours').html((hours < 10 ? '0' : '') + hours)
      }, 1000)
    })()

    var shelf = this // jquery-n belül a shelf-el hivható meg a külső this.barmi
    var com = { 16: false, 13: false } // 18-13 Alt Enter 32-13 space enter 16-13 shift ente
    $(document)
      .keydown(function (event) {
        if (event.keyCode == 27) {
          // ESC button event..
          shelf.EscapeFullSreen()
        }
        if (event.keyCode in com) {
          // shift Enter bill code combo level 1
          com[event.keyCode] = true
          if (com[16] == true && com[13] == true) {
            shelf.fullScreen()
          }
        }
      })
      .keyup(function (event) {
        // shift Enter bill code combo level 2
        if (event.keyCode in com) {
          com[event.keyCode] = false
        }
      })
  }

  public elem
  public fullScr: string = 'false'

  ngOnInit() {
    this.elem = document.documentElement
    localStorage.setItem('zoom', 'false')
    this.functions.countdownTimer()
  }

  /* Function to open fullscreen mode */
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen()
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen()
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      this.elem.webkitRequestFullscreen()
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem = window.top.document.body // To break out of frame in IE
      this.elem.msRequestFullscreen()
    }
  }

  /* Function to close fullscreen mode */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen()
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen()
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen()
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen()
    }
  }

  fullScreen() {
    if (localStorage.getItem('zoom') == 'false') {
      this.fullScr = 'true'
      localStorage.setItem('zoom', 'true')
      $('#fls').removeClass('fa fa-arrows-alt')
      $('#fls').addClass('fa fa-compress')
      $('#hed-fls').removeClass('fa fa-arrows-alt')
      $('#hed-fls').addClass('fa fa-compress')
      this.openFullscreen()
    } else if (localStorage.getItem('zoom') == 'true') {
      this.fullScr = 'false'
      localStorage.setItem('zoom', 'false')
      $('#fls').removeClass('fa fa-compress')
      $('#fls').addClass('fa fa-arrows-alt')
      $('#hed-fls').removeClass('fa fa-compress')
      $('#hed-fls').addClass('fa fa-arrows-alt')
      this.closeFullscreen()
    }
  }

  EscapeFullSreen() {
    if (localStorage.getItem('zoom') == 'true') {
      this.fullScr = 'false'
      localStorage.setItem('zoom', this.fullScr)
      $('#fls').removeClass('fa fa-compress')
      $('#fls').addClass('fa fa-arrows-alt')
      $('#hed-fls').removeClass('fa fa-compress')
      $('#hed-fls').addClass('fa fa-arrows-alt')
    }
  }
}
