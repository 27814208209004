import { Component, OnInit } from '@angular/core'
import { DataService } from '@app/_services/data.service'
import { AuthService } from '@app/_services/auth.service'
import { Router } from '@angular/router'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css'],
})
export class VideosComponent implements OnInit {
  public folders: any = {}
  public selectedFolder: string
  public emptyGroups: boolean = true
  public emptyPlayList: boolean = true
  public playlist: any = {}
  public url: any
  public currentFolder: string
  public familyFolders: boolean = false
  public friendFolders: boolean = false
  private deleted: boolean = false
  public info: boolean
  public inf_message: string
  public dialog: boolean
  public filePath: string

  public currentIndex = 0
  public currentItem = this.playlist[this.currentIndex]
  public vgPlayer

  constructor(
    public authService: AuthService,
    public router: Router,
    private data: DataService,
    private message: NzMessageService,
    private modal: NzModalService,
  ) {}

  ngOnInit() {
    this.dialog = false
    this.info = false
    this.getFolders()
  }

  familyClick(folder) {
    this.selectedFolder = folder
    this.info = false
    this.deleted = false
    this.playlist = []
    this.data.getVideoFiles(folder, 'family').subscribe((data) => {
      if (data['status'] === 200 && data['statusText'] == 'unauthorized') {
        this.authService.logout()
        this.router.navigate(['/login'])
        location.assign('/login')
      } else {
        if (data['data'].length > 0) {
          this.playlist = data['data']
          this.emptyPlayList = false
          this.currentFolder = folder
        } else {
          this.emptyPlayList = true
          this.currentFolder = ''
        }
      }
    })
  }

  friendClick(folder) {
    this.selectedFolder = folder
    this.info = false
    this.deleted = false
    this.playlist = []
    this.data.getVideoFiles(folder, 'friends').subscribe((data) => {
      if (data['status'] == 200 && data['statusText'] == 'unauthorized') {
        this.authService.logout()
        this.router.navigate(['/login'])
        location.assign('/login')
      } else {
        if (data['data'].length > 0) {
          this.playlist = data['data']
          this.emptyPlayList = false
          this.currentFolder = folder
        } else {
          this.emptyPlayList = true
          this.currentFolder = ''
        }
      }
    })
  }

  onPlayerReady(pevent) {
    this.vgPlayer = pevent
    this.vgPlayer
      .getDefaultMedia()
      .subscriptions.loadedMetadata.subscribe(this.playVideo.bind(this))
    this.vgPlayer
      .getDefaultMedia()
      .subscriptions.ended.subscribe(this.nextVideo.bind(this))
  }

  nextVideo() {
    this.currentIndex++
    if (this.currentIndex === this.playlist.length) {
      this.currentIndex = 0
    }
    this.currentItem = this.playlist[this.currentIndex]
    if (this.currentItem !== undefined) {
      this.getStream(this.currentItem.src)
    }
  }

  playVideo() {
    this.info = false
    this.vgPlayer.play()
  }

  onClickPlaylistItem(item, index: number) {
    if (!this.deleted) {
      this.currentIndex = index
      this.currentItem = item
      this.filePath = item.src
      this.getStream(item.src)
    }
  }

  getFolders() {
    this.data.getVideoFolders().subscribe((data) => {
      if (data['status'] == 200 && data['statusText'] == 'successfully') {
        if (data['data'].length > 0) {
          this.emptyGroups = false
          this.info = false
          this.folders = data['data'][0]
          if (this.folders['familyGroup'].length > 0) this.familyFolders = true
          if (this.folders['friendGroup'].length > 0) this.friendFolders = true
        } else {
          this.emptyGroups = true
          this.info = true
          this.inf_message =
            'Még senki nem hozott létre videókat tartalmazó könyvtárakat, legyél te az első!'
        }
      }
      if (data['status'] == 200 && data['statusText'] == 'unauthorized') {
        this.authService.logout()
        this.router.navigate(['/login'])
        location.assign('/login')
      }
    })
  }

  getStream(path) {
    this.url = ''
    this.data.getStreamFile(path).subscribe((data) => {
      if (data['status'] == 401 && data['statusText'] == 'unauthorized') {
        this.authService.logout()
        this.router.navigate(['/login'])
        location.assign('/login')
      } else if (data['status'] == 404) {
        console.error(
          '%c A Fájl nem található!',
          'background-color: yellow; color: red',
        )
      } else {
        this.url = data
      }
    })
  }

  deleteThisVideo(item) {
    if (item === this.currentItem || item.src === this.filePath) {
      this.message.create(
        'warning',
        'AKTÍV VIDEÓ! TÖRLÉS ELŐTT VÁLASZ MÁSIKAT!',
      )
    } else {
      this.deleted = true
      const src_datas = item.src.split('/', 3)
      const rootFolder = src_datas[0]
      const selectedFolder = src_datas[1]
      const fileName = src_datas[2]

      const modal: NzModalRef = this.modal.create({
        nzTitle: 'VIDEÓ TÖRLÉS',
        nzContent: 'Biztos törölni szeretnéd ezt a videót?\n' + fileName,
        nzFooter: [
          {
            label: 'Nem',
            // shape: 'round',
            onClick: () => {
              modal.destroy()
            },
          },
          {
            label: 'Igen, biztos',
            type: 'primary',
            danger: true,
            // loading: false,
            onClick: () => {
              modal.destroy()
              this.data
                .deleteThisVideo(fileName, rootFolder, selectedFolder)
                .subscribe((data) => {
                  if (
                    data['status'] == 200 &&
                    data['statusText'] === 'successfully'
                  ) {
                    this.message.create(
                      'success',
                      'SIKERES TÖRLSÉ! A ' + fileName + ' videót törölted.',
                    )
                    rootFolder === 'family'
                      ? this.familyClick(selectedFolder)
                      : this.friendClick(selectedFolder)
                  }
                  if (
                    data['status'] == 401 &&
                    data['statusText'] === 'unauthorized'
                  ) {
                    this.authService.logout()
                    this.router.navigate(['/login'])
                    location.assign('/login')
                  }
                  if (
                    data['status'] == 404 &&
                    data['statusText'] === 'not found'
                  ) {
                    this.message.create(
                      'warning',
                      'TÖRLÉS VISSZAVONVA!\nEzt a videót nem te hoztad létre,\nNEM TÖRÖLHETED.',
                    )
                    rootFolder === 'family'
                      ? this.familyClick(selectedFolder)
                      : this.friendClick(selectedFolder)
                  }
                })
            },
          },
        ],
      })
    }
  }
}
