<h3 class="page-title">FELTÖLTÉS</h3>

<hr class="whiter" />

<div
  style="
    -webkit-animation: text-focus-in 2s;
    margin-top: 20px;
    margin-left: 10px;
    z-index: -1;
  "
  *ngIf="img_urls; upload"
>
  <!-- feltöltés folyamatjelző -->
  <div class="progress" [ngClass]="{ upload: uploadProgress === false }">
    <div
      id="progressBar"
      class="progress-bar"
      role="progressbar"
      style="width: 0%;"
      aria-valuenow="25"
      aria-valuemax="100"
    ></div>
  </div>

  <!-- Segítség gomb -->
  <button
    class="btn BtnAll fa fa-question-circle"
    (click)="onShowHelpButton()"
    [disabled]="abortData || !validBrowser"
  >
    Segítség
    <i class="fa fa-caret-down" aria-hidden="true" *ngIf="!isShowHelp"></i>
    <i class="fa fa-caret-up" aria-hidden="true" *ngIf="isShowHelp"></i>
  </button>

  <!-- Videó kiválasztás -->
  <!-- multiple="multiple" mobilon nem nyerő...-->
  <input
    type="file"
    accept=".mp4"
    style="display: none;"
    #video
    (change)="ChooseVideoFileChange($event)"
  />
  <button
    type="button"
    id="ChosseVideoFile"
    class="btn BtnAll fa fa-video-camera"
    (click)="video.click()"
    [disabled]="abortData || !validBrowser"
  >
    Videó kiválasztása
  </button>

  <!-- kép kiválasztás -->
  <input
    type="file"
    style="display: none;"
    multiple="multiple"
    #image
    (change)="ChooseFileChange($event)"
  />
  <button
    type="button"
    id="ChosseImages"
    class="btn BtnAll fa fa-camera-retro"
    (click)="image.click()"
    [disabled]="abortData || !validBrowser"
  >
    Képek kiválasztása
  </button>

  <!-- feltöltés gomb -->
  <button
    class="btn BtnAll fa fa-upload"
    [disabled]="!ValidUpload"
    (click)="uploadClick()"
  >
    Feltöltés
  </button>

  <!-- visszavonás -->
  <button
    class="btn BtnAll fa fa-hand-o-left"
    (click)="abortLoading()"
    [disabled]="!abortData"
  >
    Vissza
  </button>

  <!-- képbetöltés folyamatjelző -->
  <div class="selected-image-loader-container">
    <div id="loader_container" [ngClass]="{ inactive: Progress === false }">
      összesen {{ count }} db / {{ db }}
      <div id="bar_container">
        <div id="progress_bar">
          <div id="progress_percentage"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Információs sáv -->
  <div
    *ngIf="info"
    class="infoGroup"
    style="
      max-height: 75px;
      max-width: 99%;
      overflow-y: auto;
      overflow-x: hidden;
    "
  >
    <label style="font-size: 1rem;">{{ info }}</label>
    <div class="row" *ngIf="existingList?.existingDatas?.length > 0">
      <div
        style="font-size: 1rem;"
        *ngFor="let data of existingList.existingDatas"
      >
        &nbsp; {{ data }} &nbsp;
      </div>
      <!-- <label style="font-size: 1rem;">
        Több kiválasztott fájl esetén, ha nem volt egyezés, akkor az
        feltöltődött. Ellenőrizd le!
      </label> -->
    </div>
    <div
      [innerHtml]="infoGp"
      style="margin-top: -10px; padding-bottom: 5px;"
    ></div>
  </div>

  <!-- Ugrás az albumhoz -->
  <button
    class="btn BtnAll"
    (click)="onJumpToUploadLocation()"
    *ngIf="successful"
  >
    Megnézem
  </button>

  <!-- Leírás - content -->
  <div
    *ngIf="message && isShowHelp"
    class="message"
    style="-webkit-animation: swing-in-top-fwd 4s;"
  >
    <h3>Némi info a feltöltéshez</h3>
    <br />
    <h4>KÉP feltöltés</h4>
    Képet egy létező albumba lehet feltölteni, amit te hoztál létre egy
    csoportban.
    <br />
    Az albumnév, például egy konkrét esemény vagy település, amivel a benne lévő
    képeket tudjuk azonosítani, hol készült, stb.
    <br />
    A csoport lehet más által létrehozott vagy te magad hozod létre. Ez lehet
    egy esemény vagy egy konrét év vagy bármi, ami tetszik.
    <br />
    <label class="example">
      Konkrét példa:
      <br />
      Első menü: "Csoportok" itt létrehozod pl.: Balukapitány 2020, miután
      elkészült, belépsz a létrehozott csoportba és
      <br />
      létrehozol egy albumot pl.: Kiskacsa fürdik.
      <br />
      Végül a feltöltések menüben ezeket szépen ki tudod választani!
      <br />
      "Képek kiválasztása" gomb után, a feltöltés gombra kattintva megjelenik
      egy lista
      <br />
      előbb válaszd ki a csoportot, majd az abban lévő albumot.
    </label>
    <br />
    <br />
    <h4>HÁTTÉRKÉP feltöltés</h4>
    Az oldalon beállíthatod a saját kedvenc képedet, mint háttérkép. A
    kinagyított képen a felső ikonok között, BEÁLLÍTÁS MINT: "Háttérkép"
    <br />
    vagy akár a feltöltés gombra kattintva, a csoport kiválasztásánál válaszd a
    háttérkép opciót, majd mehet.
    <br />
    <br />
    <img
      src="assets/img/background_image_upload.jpg"
      alt="Sample Image Title"
    />
    <br />
    <br />
    Bármelyik módszert használtad a "Beállítások" menüben megtalálod a képet és
    kiválaszthatod.
    <br />
    Az ilyen képek feltöltése korlátozott mennyiségű, éppúgy mint a privát
    képek.
    <br />
    <br />
    <h4>VIDEÓ feltöltés</h4>
    A videó kiválasztásánál ügyelj arra, hogy a kiterjesztése csak mp4 lehet, a
    mérete pedig ne legyen nagyobb 2Gb-nál.
    <br />
    A feltöltés gombra kattintva a kiválasztott mappában a videó fájlnév egyedi
    legyen, ha van ugyan olyan, akkor a feltöltés visszavonásra kerül.
    <br />
    Itt lehetőség van egyedi mappa létrehozására is (pl. év vagy rövid becenév).
    <br />
    A létrehozott mappát egyelőre törölni még nem lehet, majd egyszer, talán...
    <br />
    Webes vagy letölthető video konvertáló itt érhető el:
    <br />
    <a href="https://video.online-convert.com/convert-to-mp4" target="_blank">
      webes: video.online-convert.com
    </a>
    <br />
    <a href="https://www.onlinefreeconvert.com/mkv-to-mp4.html" target="_blank">
      webes: onlinefreeconvert.com
    </a>
    <br />
    <a href="https://www.freemake.com/free_video_converter/" target="_blank">
      letölthető: free converter
    </a>
    <br />
    <br />
    <h4>EGYÉB info</h4>
    A feltöltött képet, videót vagy létrehozott csoportot, albumot csak a
    létrehozó tudja törölni!
    <br />
    Meg én :)
    <br />
    A csoportot vagy csoportokat, csak akkor lehet törölni, ha nem tartalmaz
    albumot.
    <br />
    Mindenki a saját létrehozott dolgainak a tulajdonosa!
    <br />
    Ha valamit elírtál vagy nem tetszik, szólj és átnevezem.
    <br />
    <br />
    Üdv, Isti
    <br />
    <a href="mailto: szvetlikistvan@gmail.com">szvetlikistvan@gmail.com</a>
    <br />
    <a href="tel:+36302211213">+36/30/221-1213</a>
    <br />
    <br />
  </div>

  <!-- videó feltöltés folyamatjelző -->
  <div class="row" *ngIf="loader == true" style="z-index: 9999;">
    <div class="dot-loader"></div>
    <div class="dot-loader"></div>
    <div class="dot-loader"></div>
  </div>

  <!-- csoport és album választó -->
  <div
    *ngIf="location"
    class="row"
    class="albumSelect"
    style="-webkit-animation: swing-in-top-fwd 2s; overflow: hidden;"
  >
    <div class="select-content">
      <label
        style="
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 15px;
          font-size: 1rem;
        "
      >
        Válasz csoportot
      </label>
      <app-nz-select
        *ngIf="groupsList?.length > 0"
        [options]="groupsList"
        (option)="groupSelectClick($event)"
      ></app-nz-select>
    </div>

    <div class="select-content">
      <label
        *ngIf="isSelectedGroup && albumList?.length !== 0"
        style="
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 15px;
          font-size: 1rem;
        "
      >
        Válasz albumot
      </label>
      <label
        *ngIf="albumList?.length === 0 && backgroundPicture === false"
        class="empty-group"
      >
        Üres csoport!
      </label>

      <app-nz-select
        *ngIf="isSelectedGroup"
        [options]="albumList"
        (option)="albumSelectClick($event)"
      ></app-nz-select>
    </div>

    <button class="btn saveBtn" (click)="send()" [disabled]="!up">Mehet</button>
  </div>

  <!-- Videó mappa választó -->
  <div
    *ngIf="videoLocation"
    class="row"
    class="albumSelect"
    style="-webkit-animation: swing-in-top-fwd 2s; overflow: hidden;"
  >
    <label class="folder-select-title">
      Válasz mappát:
    </label>

    <div class="select-content">
      <label *ngIf="foldersListFa?.length > 0 && !chosenFa">
        Család&nbsp;&nbsp;
      </label>

      <app-nz-select
        class="selected"
        *ngIf="foldersListFa && !chosenFa"
        [options]="foldersListFa"
        (option)="SelectFaFolderClick($event)"
      ></app-nz-select>
    </div>

    <div class="select-content">
      <label *ngIf="foldersListFr?.length > 0 && !chosenFr">
        &nbsp;&nbsp;Barát&nbsp;&nbsp;
      </label>

      <app-nz-select
        class="selected"
        *ngIf="foldersListFr && !chosenFr"
        [options]="foldersListFr"
        (option)="SelectFrFolderClick($event)"
      ></app-nz-select>
    </div>

    <!-- Új mappa létrehozás -->
    <input
      matTooltip="Év, rövid név (max 10 karakter)"
      matTooltipPosition="below"
      *ngIf="newFolder"
      type="text"
      class="input-focused word"
      placeholder="Mappa név"
      maxlength="10"
      #folderNameBox
      (keyup)="folderNameBoxValue($event)"
    />

    <button
      class="btn saveBtn"
      (click)="videoToPrepare()"
      [disabled]="!upVideo"
    >
      Mehet
    </button>
  </div>

  <!-- Kiválasztott képek listája -->
  <div
    class="row search-results"
    *ngIf="loadImg"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScroll()"
    id="img_urls"
  >
    <div
      class="gallery-item col-md-2 col-sm-3 col-xs-4"
      *ngFor="let image of this.files; let i = index"
      style="-webkit-animation: swing-in-top-fwd 3s; margin-top: 20px;"
    >
      <div class="groups_item">
        <div class="group-item-img">
          <a style="cursor: pointer;">
            <img class="groups_img" [src]="image.imgUrl" alt="kép nem található" />
          </a>
        </div>

        <div class="groups_info">
          <label>Fájlnév:</label>
          <!-- {{ upload[i].name | cutExtension}} -->
          {{ image.name | cutExtension}}
          <br />
          <label>Utolsó módosítás:</label>
          <br />
          <!-- {{ upload[i].lastModified | date: 'yyyy-MM-dd' }} -->
          {{ image.lastModified | date: 'yyyy-MM-dd' }}
          <br />
          <label>Méret:</label>
          <!-- {{ upload[i].size / 1024 / 1024 | number }}&nbsp;&nbsp; -->
          {{ image.size / 1024 / 1024 | number }}&nbsp;&nbsp;
          <label>Mb</label>
          <br />
          <label>Méretek:</label>
          <!-- {{ imgWH[i] }} -->
          {{image.widthAndHeight}}
          <br />
          <label>Fájl kiterjesztés:</label>
          <!-- {{ upload[i].extension }} -->
          {{image.extension}}
          <br />
        </div>

        <button class="btn BtnAll upload-btn" (click)="delUploadClick(i)">
          Törlés
        </button>
      </div>
    </div>
  </div>

  <!-- Kiválasztott videók listája -->
  <div class="row fileslist" *ngIf="ChooseVideo">
    <label class="video-filelist-title">
      Kiválasztva
    </label>
    <br />
    <ul style="margin-left: -38px; overflow-y: auto; height: 48%;">
      <li
        class="fileslist-item"
        *ngFor="let item of videosList; let $index = index"
        (click)="onClickFileItem($index)"
        [class.selected]="$index === currentIndex"
      >
        <label class="">
          <b>Fájlnév:</b>
          &nbsp;{{ item.name }}&nbsp;
        </label>
        <label class="">
          <b>Kiterjesztés:</b>
          &nbsp;{{ item.extension }}&nbsp;
        </label>
        <label class="">
          <b>Méret:</b>
          &nbsp;{{ item.size / 1024 / 1024 | number }}&nbsp;Mb&nbsp;&nbsp;
        </label>
        <button
          class="btn BtnAll delete-video-btn"
          type="submit"
          (click)="deleteThisVideo($index)"
        >
          Töröl
        </button>
      </li>
    </ul>
  </div>
</div>
