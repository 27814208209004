import { Component, OnInit, Inject } from '@angular/core'
import { AuthService } from '@app/_services/auth.service'
import { Router } from '@angular/router'
import { DataService } from '@app/_services/data.service'
import { DOCUMENT } from '@angular/common'
import * as $ from 'node_modules/jquery'
import { trigger, state, style, animate, transition } from '@angular/animations'
import * as FileSaver from 'node_modules/file-saver'
import { DeleteImagesResponse } from '../_definitions/images.definition'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css'],
  animations: [
    trigger('photoState', [
      state(
        'move',
        style({
          transform: 'translateX(-100%)',
        }),
      ),
      state(
        'enlarge',
        style({
          transform: 'scale(1.5)',
        }),
      ),
      state(
        'spin',
        style({
          transform: 'rotateZ(90deg)', // tükröz és/vagy forgat: 'rotateY(180deg) rotateZ(90deg)',
          height: '50%',
        }),
      ),
      transition('spin => move', animate('2000ms ease-out')),
      transition('* => *', animate('500ms ease')),
    ]),
  ],
})
export class ImagesComponent implements OnInit {
  public transNumber: number = 1 //* történik -e lapozás, vagy play opcio
  public fullScr: string = 'false'

  public images: any
  public emptyImage: boolean = false
  public startIndex = 0
  public lastIndex = 0
  public imgNumber = 1
  public imgAllNumber = 0
  public elem
  public count = 0
  public fulldata = 0

  private gid = localStorage.getItem('gid')
  private albId = localStorage.getItem('abid')
  private picId = ''
  private auto = 4
  public position: string
  public change_img: string = 'enlarge'
  public rotate: boolean = false
  public onInfo: boolean = false
  private onDelete: boolean

  public activePS: boolean = false
  public groupName: string
  public albumName: string

  constructor(
    @Inject(DOCUMENT)
    private document: any,
    public authService: AuthService,
    private router: Router,
    private data: DataService,
    private modal: NzModalService,
    private message: NzMessageService,
  ) {
    var shelf = this
    var com = { 16: false, 13: false } //* 18-13 Alt Enter 32-13 space enter 16-13 shift enter
    $(document)
      .keydown(function (event) {
        if (event.keyCode == 27) {
          shelf.closeModalImage()
          shelf.transNumber = 1
        }
        if (event.keyCode == 37) {
          // left
          shelf.previousClick(shelf.imgNumber - 1)
        }
        if (event.keyCode == 39) {
          // right
          shelf.nextClick(shelf.imgNumber - 1)
        }
        if (event.keyCode == 38) {
          // up
          shelf.playClick(shelf.imgNumber - 1)
        }
        if (event.keyCode == 40) {
          // down
          shelf.playStopClick(shelf.imgNumber - 1)
        }
        if (event.keyCode == 44) {
          // printScreen
        }
        // if (event.keyCode in com) {
        //   com[event.keyCode] = true;
        //   if (com[16] && com[13]) {
        //     shelf.fullScreen()
        //   }
        // }
      })
      .keyup(function (event) {
        // if (event.keyCode in com) {
        //     com[event.keyCode] = false;
        // }
        if (event.keyCode == 44) {
          //* printScreen
          shelf.closeModalImage()
          shelf.copyToClipboard()
          var attr = document
            .getElementById('photo-gallery-alt')
            .getAttribute('class')
        }
      })

    $('#modalImages').mousedown(function (event) {
      if (event.button == 2) {
        // right click
        return false // do nothing!
      }
    })
    $(document).ready(function () {
      $('img').bind('contextmenu', function (e) {
        return false
      })
    })
  }

  ngOnInit() {
    this.onDelete = false
    this.elem = document.documentElement

    this.data
      .getValidToken(localStorage.getItem('currentUser'))
      .subscribe((data) => {
        if (data == false) {
          this.authService.logout()
          this.router.navigate(['/login'])
          location.assign('/login')
        }
      })

    if (localStorage.getItem('abid') !== '') {
      this.groupName = localStorage.getItem('gname')
      this.albumName = localStorage.getItem('albumName')
      this.getImages(this.albId, this.count)
    }
  }

  //*--------------------------------------------------------------------- Functions -----------------------------------------------------------------------
  public isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0
  }

  public delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  private base64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1])
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length)
    var buffer = new Uint8Array(ab)

    for (var i = 0; i < byteString.length; i++) {
      buffer[i] = byteString.charCodeAt(i)
    }

    var blob = new Blob([ab], { type: mimeString })
    return blob
  }

  private copyToClipboard() {
    var aux = document.createElement('input')
    aux.setAttribute(
      'value',
      'Kérlek ne használd ezen az oldalon a PrtScr funkciót!',
    )
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    //* alert("A nyomtatási képernyő letiltva!");
    //* $("#onPS").click();
    $(window)
      .focus(function () {
        $('.whiter').show()
      })
      .blur(function () {
        $('.whiter').hide()
      })
  }

  PSERR() {
    // console.log("PS")
  }

  //*-------------------------------------------------------------------------------------------------------------------------------------------------------
  //*--------------------------------------------------------------------- Procedures ----------------------------------------------------------------------
  getImages(abid, count) {
    this.albId = abid
    this.data.getImages(abid, count).subscribe((data) => {
      if (this.isEmptyObject(data['data']) == true) {
        this.emptyImage = this.isEmptyObject(data['data'])
      }
      if (
        data['status'] == 200 &&
        data['statusText'] == 'successfully' &&
        this.isEmptyObject(data['data']) == false
      ) {
        this.images = data
        this.lastIndex = data['data'].length - 1
        this.imgAllNumber = data['fulldata']
        this.count = data['data']['length']
        this.fulldata = data['fulldata']
        if (this.count != 0 && this.count < this.fulldata) {
          this.getMore()
        }
      }
    })
  }

  getMore() {
    if (this.count < this.fulldata) {
      this.data.getImages(this.albId, this.count).subscribe((data) => {
        if (
          data['status'] == 200 &&
          data['statusText'] == 'successfully' &&
          this.isEmptyObject(data['data']) == false
        ) {
          var e = 0
          for (
            var i = data['data'][0].index;
            i <= data['data'][data['data'].length - 1].index;
            i++
          ) {
            this.images['data'][i] = {
              filename: data['data'][e].filename,
              height: data['data'][e].height,
              index: data['data'][e].index,
              makingDate: data['data'][e].makingDate,
              modifyDate: data['data'][e].modifyDate,
              picId: data['data'][e].picId,
              picture: data['data'][e].picture,
              size: data['data'][e].size,
              width: data['data'][e].width,
            }
            e += 1
          }
          this.count = this.images['data'].length
          if (this.count < this.fulldata) {
            this.auto = this.auto - 1
            if (this.auto >= 0) {
              this.getMore()
            }
          }
        }
      })
    }
  }

  onScroll() {
    if (this.auto <= 0) {
      this.data.getImages(this.albId, this.count).subscribe((data) => {
        if (
          data['status'] == 200 &&
          data['statusText'] == 'successfully' &&
          this.isEmptyObject(data['data']) == false
        ) {
          var e = 0
          for (
            var i = data['data'][0].index;
            i <= data['data'][data['data'].length - 1].index;
            i++
          ) {
            this.images['data'][i] = {
              filename: data['data'][e].filename,
              height: data['data'][e].height,
              index: data['data'][e].index,
              makingDate: data['data'][e].makingDate,
              modifyDate: data['data'][e].modifyDate,
              picId: data['data'][e].picId,
              picture: data['data'][e].picture,
              size: data['data'][e].size,
              width: data['data'][e].width,
            }
            e += 1
          }
          this.count = this.images['data'].length
        }
      })
    }
  }

  albumsClick() {
    this.router.navigate(['/albums'])
  }

  groupClick() {
    this.router.navigate(['/home'])
  }

  clickImage(index, picId) {
    this.startIndex = index
    this.imgNumber = index + 1
    this.picId = picId
  }

  previousClick(currentIndex) {
    this.transNumber = 1
    if (currentIndex > 0) {
      this.startIndex = currentIndex - 1
    } else {
      this.startIndex = 0
    }
    this.imgNumber = this.startIndex + 1
    this.picId = this.images['data'][this.startIndex]['picId']
  }

  nextClick(currentIndex) {
    this.transNumber = 1
    if (currentIndex < this.images['data'].length - 1) {
      this.startIndex = currentIndex + 1
    } else {
      this.startIndex = this.images['data'].length - 1
    }
    this.imgNumber = this.startIndex + 1
    this.picId = this.images['data'][this.startIndex]['picId']
    if (
      currentIndex + 2 == this.images['data'].length - 5 &&
      this.images['data'].length != this.fulldata
    ) {
      this.onScroll()
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen()
    } else if (this.elem.mozRequestFullScreen) {
      //* Firefox
      this.elem.mozRequestFullScreen()
    } else if (this.elem.webkitRequestFullscreen) {
      //* Chrome, Safari & Opera
      this.elem.webkitRequestFullscreen()
    } else if (this.elem.msRequestFullscreen) {
      //* IE/Edge
      this.elem = window.top.document.body //* To break out of frame in IE
      this.elem.msRequestFullscreen()
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen()
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen()
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen()
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen()
    }
  }

  fullScreen() {
    if (localStorage.getItem('zoom') == 'false') {
      this.fullScr = 'true'
      localStorage.setItem('zoom', 'true')
      //* zoom buttom event class:
      $('#fls').removeClass('fa fa-arrows-alt')
      $('#fls').addClass('fa fa-compress')
      $('#hed-fls').removeClass('fa fa-arrows-alt')
      $('#hed-fls').addClass('fa fa-compress')
      this.openFullscreen()
    } else if (localStorage.getItem('zoom') == 'true') {
      this.fullScr = 'false'
      localStorage.setItem('zoom', this.fullScr)
      //* zoom buttom event class:
      $('#fls').removeClass('fa fa-compress')
      $('#fls').addClass('fa fa-arrows-alt')
      $('#hed-fls').removeClass('fa fa-compress')
      $('#hed-fls').addClass('fa fa-arrows-alt')
      this.closeFullscreen()
    }
  }

  closeModalImage() {
    if (this.onDelete) {
      this.onDelete = false
      this.count = 0
      this.getImages(this.albId, this.count)
    }
    $('#modalClose').click()
    if (localStorage.getItem('zoom') == 'true') {
      this.fullScr = 'false'
      localStorage.setItem('zoom', this.fullScr)
      $('#fls').removeClass('fa fa-compress')
      $('#fls').addClass('fa fa-arrows-alt')
      $('#hed-fls').removeClass('fa fa-compress')
      $('#hed-fls').addClass('fa fa-arrows-alt')
    }
  }

  playClick(currentIndex) {
    this.transNumber = 0
    this.nextPage(currentIndex)
  }
  playStopClick(currentIndex) {
    this.transNumber = 1
  }
  nextPage(currentIndex) {
    setTimeout(() => {
      if (
        currentIndex + 2 == this.images['data'].length - 5 &&
        this.images['data'].length != this.fulldata
      ) {
        this.onScroll()
      }

      if (this.startIndex >= this.images['data'].length - 1) {
        this.startIndex = 0
        this.imgNumber = this.startIndex + 1
        this.nextPage(this.startIndex)
      }

      if (
        currentIndex < this.images['data'].length - 1 &&
        this.transNumber == 0
      ) {
        this.startIndex = currentIndex + 1
        this.imgNumber = this.startIndex + 1
        this.nextPage(this.startIndex)
      }
    }, 3000)
  }

  setGroupImageClick() {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'CSOPORTKÉP BEÁLLÍTÁS',
      nzContent: 'Biztos beállítod ezt a képet a csoportnak?',
      nzFooter: [
        {
          label: 'Nem',
          // shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen',
          type: 'primary',
          // danger: false,
          // loading: false,
          onClick: () => {
            modal.destroy()
            this.data.setgroupimage(this.picId, this.gid).subscribe((data) => {
              if (data['status'] == 200) {
                this.message.create('success', 'A csoportkép beállítás sikeres')
              } else {
                this.message.create(
                  'error',
                  'A csoportkép beállítás sikertelen',
                )
              }
            })
          },
        },
      ],
    })
  }

  setAlbumImageClick() {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'ALBUMKÉP BEÁLLÍTÁS',
      nzContent: 'Biztos beállítod ezt a képet az albumnak?',
      nzFooter: [
        {
          label: 'Nem',
          // shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen',
          type: 'primary',
          // danger: false,
          // loading: false,
          onClick: () => {
            modal.destroy()
            this.data
              .setalbumimage(this.picId, this.albId)
              .subscribe((data) => {
                if (data['status'] == 200) {
                  this.message.create(
                    'success',
                    'Az album borítókép beállítása sikeres',
                  )
                } else {
                  this.message.create(
                    'error',
                    'Az album borítókép beállítás sikertelen!',
                  )
                }
              })
          },
        },
      ],
    })
  }

  setUserProfilClick() {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'PROFILKÉP BEÁLLÍTÁS',
      nzContent:
        'Biztos beállítod ezt a képet a saját profilképednek? Következő belépéskor már érvényes lesz.',
      nzFooter: [
        {
          label: 'Nem',
          // shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen',
          type: 'primary',
          // danger: false,
          // loading: false,
          onClick: () => {
            modal.destroy()
            this.data.setuserprofilimage(this.picId).subscribe((data) => {
              if (data['status'] == 200) {
                this.message.create('success', 'A profilkép beállítás sikeres')
              } else {
                this.message.create(
                  'error',
                  'A profilkép beállítása sikertelen!',
                )
              }
            })
          },
        },
      ],
    })
  }

  setUserBackground() {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'HÁTTÉRKÉP HOZZÁADÁS',
      nzContent: 'Biztos hozzáadod ezt a képet a saját háttérkép listádhoz?',
      nzFooter: [
        {
          label: 'Nem',
          // shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen',
          type: 'primary',
          // danger: false,
          // loading: false,
          onClick: () => {
            modal.destroy()
            this.data.copyToWallpaper(this.picId).subscribe((data) => {
              if (
                data['status'] === 200 &&
                data['statusText'] == 'successfully'
              ) {
                this.message.create(
                  'success',
                  'A háttérkép hozzáadva. Lásd a beállításoknál!',
                )
              } else if (
                data['status'] === 200 &&
                data['statusText'] == 'limited'
              ) {
                this.message.create(
                  'error',
                  'Elérted a maximális háttérkép számát.',
                )
              }
            })
          },
        },
      ],
    })
  }

  delThisImageClick(picId, index) {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'KÉP TÖRLÉS',
      nzContent: 'Biztos törölni szeretnéd ezt a képet?',
      nzFooter: [
        {
          label: 'Nem, kizárt',
          shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen, biztos',
          type: 'primary',
          danger: true,
          loading: false,
          onClick: () => {
            modal.destroy()
            this.deleteImage(picId, index)
          },
        },
      ],
    })
  }

  private deleteImage(picId, index): void {
    this.data.delthisimage(picId).subscribe((data: DeleteImagesResponse) => {
      if (data?.status === 200) {
        this.onDelete = true
        if (this.imgAllNumber >= 2) {
          this.images['data'].splice(index - 1, 1)
          this.imgAllNumber = this.imgAllNumber - 1
          this.nextClick(index - 1)
        } else if (this.imgAllNumber <= 1) {
          $('#modalClose').click()
          $('div').remove('.block-area')
          this.getImages(localStorage.getItem('abid'), 0)
        }
        this.message.create(
          'success',
          'A ' + data.deletedImageName + ' kép törlése sikeres!',
        )
      } else if (data?.status === 304) {
        let msg = 'A kép használatban van itt: '
        for (var u = 0; u < data['used'].length; u++) {
          msg += data['used'][u]['located'] + '  '
        }
        this.message.create('error', msg)
      } else if (data.status === 405) {
        this.message.create(
          'error',
          'A képet csak a létrehozó tulajdonos törölheti!',
        )
      }
    })
  }

  rotateImage() {
    if (this.rotate == false) {
      this.position = 'spin'
      this.rotate = true
    } else {
      this.position = 'null'
      this.rotate = false
    }
  }
  changePosition() {
    let side = $('#zoom')
    if (side.hasClass('fa fa-search-plus')) {
      side.removeClass()
      side.addClass('fa fa-search-minus')
      this.position = 'enlarge'
    } else if (side.hasClass('fa fa-search-minus')) {
      side.removeClass()
      side.addClass('fa fa-search-plus')
      this.position = 'null'
    }
  }
  logAnimation($event) {
    var self = this
  }

  upload() {
    localStorage.setItem('menu', '/uploading')
    location.assign('/uploading')
  }

  downloadImage(imgData) {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'KÉPLETÖLTÉS',
      nzContent: 'Biztos letöltöd a képet?',
      nzFooter: [
        {
          label: 'Nem',
          // shape: 'round',
          onClick: () => {
            modal.destroy()
          },
        },
        {
          label: 'Igen',
          type: 'primary',
          // danger: false,
          // loading: false,
          onClick: () => {
            modal.destroy()
            this.data.downloadimage(imgData.picId).subscribe((data) => {
              if (data['status'] === 200) {
                const myFile = this.base64toBlob(imgData.picture)
                FileSaver.saveAs(myFile, imgData.filename)
                this.message.create('success', 'A kép letöltve!')
              } else if (data['status'] === 405) {
                this.message.create(
                  'error',
                  'A képet csak a létrehozója töltheti le!',
                )
              }
            })
          },
        },
      ],
    })
  }

  infoClick() {
    if (this.onInfo) {
      this.onInfo = false
    } else {
      this.onInfo = true
    }
  }
}
