import { Component, OnInit } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { AuthService } from '@app/_services/auth.service'
import { EncryptService } from '@app/_services/encrypt.service'
import { DataService } from '@app/_services/data.service'
import { Router } from '@angular/router'
declare var $: any

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup
  message: string
  returnUrl: string
  loginBox: boolean

  login_datas: any
  status: number
  server_init = false

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    public encrypt: EncryptService,
    private data: DataService,
  ) {}

  ngOnInit() {
    this.init()
    this.loginBox = false
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })

    this.returnUrl = '/home'

    if (localStorage.getItem('token')) {
      location.assign('/home')
    }
  }

  get loginF() {
    return this.loginForm.controls
  }

  init() {
    this.data.getInit().subscribe({
      next: (res: boolean) => {
        this.server_init = res ? res : false
      },
      error: (e) => {
        console.error('init error: ', e)
      },
    })
  }

  showLgBox() {
    this.loginBox = this.loginBox === false ? true : false
  }

  login() {
    if (this.loginForm.invalid) {
      this.message = 'Ellenőrizd a beírt adatokat!'
      return
    } else {
      var password = this.encrypt.setHash(this.loginF.password.value)

      this.data
        .getLogin(this.loginF.username.value, password)
        .subscribe((data) => {
          this.status = data['status']
          if (
            this.status === 200 &&
            data['statusText'] == 'authenticated_successfully'
          ) {
            localStorage.setItem('isLoggedIn', 'true')
            localStorage.setItem('currentUser', data['name'])
            localStorage.setItem('token', data['token'])
            localStorage.setItem('tokenValid', data['valid'])
            localStorage.setItem('picId', data['picId'])
            localStorage.setItem('menu', this.returnUrl)
            this.router.navigate([this.returnUrl])
            location.assign('/home')
          }
          if (this.status === 200 && data['statusText'] == 'authenticated') {
            localStorage.setItem('isLoggedIn', 'true')
            localStorage.setItem('currentUser', data['name'])
            localStorage.setItem('token', data['token'])
            localStorage.setItem('tokenValid', data['valid'])
            localStorage.setItem('picId', data['picId'])
            localStorage.setItem('menu', this.returnUrl)
            this.router.navigate([this.returnUrl])
            location.assign('/home')
          }
          if (this.status === 401) {
            this.message = 'Hibás név vagy jelszó!'
            this.authService.logout()
            this.router.navigate(['/login'])
          }
          if (this.status === 403) {
            this.message = 'Hibás NÉV vagy jelszó!'
            this.authService.logout()
            this.router.navigate(['/login'])
          }
        })
    }
  }
}
