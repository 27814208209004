<div class="row" style="height: 42px;">
  <div class="media-body">
  
    <div class="media" id="top-menu">

      <a><i id="menu-toggle"></i></a>

      <div class="media-body">

        <div class="logoBox">
            <div class="ro">
                <a class="logo">
                  <img class="imgLogo" src="assets/img/szieramedia.png" alt="kép nem található">
                </a>
            </div>
        </div> 

        <div class="countdownTimer">
          Kilépés: <span id="countdown"></span>
        </div>
        
      </div>
      
      <div class="media-footer">
    
        <i id="hed-fls" class="fa fa-arrows-alt"
            style="margin-top: 18px; margin-right: 15px; cursor: pointer; font-size: 15px;"
            (click)="fullScreen()">
        </i>

      </div>
      
      <div id="time" class="pull-right">
          <span id="hours"></span>
          :
          <span id="min"></span>
          :
          <span id="sec"></span>
      </div>
      
    </div> 
          
  </div>  
</div>
  
    
