import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Guid } from 'guid-typescript'
import { HeaderProvider } from '@app/_provider/header.provider'
import { forkJoin, Observable } from 'rxjs'
import { environment } from '@src/environments/environment'

let header_provider = new HeaderProvider()
let header = header_provider.getAuthorizationHeader(
  localStorage.getItem('token'),
)
let options = {
  headers: header,
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // private url = environment.testApiURL // ! TESZT URL
  private url = environment.apiUrl // ! éles URL

  constructor(private http: HttpClient) {}

  getInit() {
    var url = this.url + 'init'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let header = {
      headers: headers,
    }
    return this.http.post(url, header)
  }

  /* ************************************************************************************************* */
  /**                                                                                   ** U S E R  **
  /* ************************************************************************************************* */

  getLogin(username, password) {
    var url = this.url + 'login'
    localStorage.setItem('session', Guid.create() as any) // Új session GUID mint string

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('session'),
    })
    // headers.append('Access-Control-Allow-Headers', 'Authorization');
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Access-Control-Allow-Credentials', 'true');
    // headers.append('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT');
    // headers.append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Auth');
    let header = {
      headers: headers,
    }

    var params = {
      username: username,
      password: password,
    }
    return this.http.post(url, params, header)
  }

  getLogout() {
    var url = this.url + 'logout'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }

    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  getValidToken(username) {
    var url = this.url + 'validtoken'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: username,
    }
    return this.http.post(url, params, header)
  }

  getRefreshToken(username) {
    var url = this.url + 'refreshToken'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: username,
    }
    return this.http.post(url, params, header)
  }

  setPassword(oldPassword, newPassword) {
    var url = this.url + 'setpassword'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      oldpwd: oldPassword,
      newpwd: newPassword,
    }
    return this.http.post(url, params, header)
  }

  setEmail(email) {
    var url = this.url + 'updateemail'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      email: email,
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  getEmail() {
    var url = this.url + 'getuseremail'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  getBgPictures() {
    var url = this.url + 'getBgPictures'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  setBgActiveImage(bgId) {
    var url = this.url + 'setBgActiveImage'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      bgId: bgId,
    }
    return this.http.post(url, params, header)
  }

  getBgPicture() {
    var url = this.url + 'getBgPicture'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  uploadBgPictures(pictures) {
    var url = this.url + 'uploadBgPictures'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      pictures: pictures,
    }
    return this.http.post(url, params, header)
  }

  copyToWallpaper(picId) {
    var url = this.url + 'copyToWallpaper'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      picId: picId,
    }
    return this.http.post(url, params, header)
  }

  deleteBgWallpaper(bgId) {
    var url = this.url + 'deleteBgWallpaper'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      bgId: bgId,
    }
    return this.http.post(url, params, header)
  }

  /* ************************************************************************************************* */
  /**                                                                               ** G R O U P S  **
/* ************************************************************************************************* */

  getHomeDatas(): Observable<any> {
    return forkJoin({
      loggedInUserGroups: this.getGroupsOfLoggedinUser(),
      CategorizedGroups: this.getCategorizedGroups(),
    })
  }

  getGroupsOfLoggedinUser() {
    var url = this.url + 'groupsofloggedinuser'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  getCategorizedGroups() {
    var url = this.url + 'categorizedgroups'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  getUserGroups() {
    var url = this.url + 'selectusergroups'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  getUserShare() {
    var url = this.url + 'selectusershare'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  setGroup(groupname, descriptation, shid) {
    var url = this.url + 'setnewgroups'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      groupName: groupname,
      descriptation: descriptation,
      shId: shid,
    }
    return this.http.post(url, params, header)
  }

  deleteGroups(groupsIdList) {
    var url = this.url + 'deletegroups'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      groupsIdList: groupsIdList,
    }
    return this.http.post(url, params, header)
  }

  /* ************************************************************************************************* */
  /**                                                                               ** A L B U M S  **
/* ************************************************************************************************* */

  getGroupAlbums(groupName) {
    var url = this.url + 'groupalbums'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      groupName: groupName,
    }
    return this.http.post(url, params, header)
  }

  setnewalbums(albumName, groupId, descriptation) {
    var url = this.url + 'setnewalbums'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      albumName: albumName,
      groupId: groupId,
      descriptation: descriptation,
    }
    return this.http.post(url, params, header)
  }

  deletegroupalbums(albumIdList) {
    var url = this.url + 'deletegroupalbums'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      albumIdList: albumIdList,
    }
    return this.http.post(url, params, header)
  }

  usergroupalbums(groupName) {
    var url = this.url + 'usergroupalbums'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      groupName: groupName,
    }
    return this.http.post(url, params, header)
  }

  /* ************************************************************************************************* */
  /**                                                                           ** P I C T U R E S  **
  /* ************************************************************************************************* */

  setPictures(pictures, albumId) {
    var url = this.url + 'setpictures'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      pictures: pictures,
      albumId: albumId,
    }
    console.log('http hívás params',params)
    return this.http.post(url, params, header)
  }

  getImages(albumId, count) {
    var url = this.url + 'getpictures'
    var params = {
      name: localStorage.getItem('currentUser'),
      albumId: albumId,
      count: count,
    }
    return this.http.post(url, params, options)
  }

  getImage(picId) {
    var url = this.url + 'getpicture'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      picId: picId,
    }
    return this.http.post(url, params, header)
  }

  setgroupimage(picId, groupId) {
    var url = this.url + 'setgroupimage'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      picId: picId,
      groupId: groupId,
    }
    return this.http.post(url, params, header)
  }

  setalbumimage(picId, albId) {
    var url = this.url + 'setalbumimage'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      picId: picId,
      albId: albId,
    }
    return this.http.post(url, params, header)
  }

  setuserprofilimage(picId) {
    var url = this.url + 'setuserprofilimage'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      picId: picId,
    }
    return this.http.post(url, params, header)
  }

  delthisimage(picId) {
    var url = this.url + 'deletethisimage'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      picId: picId,
    }
    return this.http.post(url, params, header)
  }

  downloadimage(picId) {
    var url = this.url + 'downloadimage'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      picId: picId,
    }
    return this.http.post(url, params, header)
  }

  /* ************************************************************************************************* */
  /**                                                                           ** V I D E O S  **
  /* ************************************************************************************************* */
  getVideoFolders() {
    var url = this.url + 'getVideoFolders'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  getVideoFiles(folder, group) {
    var url = this.url + 'getVideoFiles'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      folder: folder,
      group: group,
    }
    return this.http.post(url, params, header)
  }

  getStreamFile(filePath) {
    var url = this.url + 'getStreamFile'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      filePath: filePath,
    }
    return this.http.post(url, params, header)
  }

  upVideo(file, vDatas, allSlice, currentPiece) {
    var url = this.url + 'upVideo'
    let headers = new HttpHeaders({
      Auth: localStorage.getItem('token'),
      'Content-Type': 'application/json',
    })
    let header = {
      headers: headers,
    }
    var params = {
      file: file,
      vDatas: vDatas,
      allSlice: allSlice,
      currentPiece: currentPiece,
      name: localStorage.getItem('currentUser'),
    }
    return this.http.post(url, params, header)
  }

  deleteThisVideo(fileName, rootFolder, folder) {
    var url = this.url + 'deleteThisVideo'
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Auth: localStorage.getItem('token'),
    })
    let header = {
      headers: headers,
    }
    var params = {
      name: localStorage.getItem('currentUser'),
      fileName: fileName,
      rootFolder: rootFolder,
      folder: folder,
    }
    return this.http.post(url, params, header)
  }
}
