<h3 class="page-title">
  <div class="title">CSOPORTOK</div>
</h3>
<hr class="whiter" />
<br />

<div class="row btnLine" style="-webkit-animation: swing-in-top-fwd 4s;">
  <button class="btn BtnAll" (click)="newGroupClick()" *ngIf="!newGroup">
    Új csoport
  </button>
  <button
    class="btn BtnAll"
    (click)="chooseGroupsItems()"
    *ngIf="!delGroups && !newGroup && !isEmptyUserGroup"
  >
    Kijelölés
  </button>

  <button class="btn BtnAll" (click)="onCancelButtonClick()" *ngIf="newGroup">
    Mégsem
  </button>

  <button
    class="btn BtnAll"
    (click)="deleteGroupsCancelClick()"
    *ngIf="delGroups"
  >
    Mégsem
  </button>
  <button
    class="btn BtnAll"
    (click)="onDeleteGroupButtonClicked()"
    *ngIf="delGroups && delIdList['length'] > 0"
  >
    Töröl
  </button>
</div>

<div
  class="row"
  class="groupSelect"
  style="-webkit-animation: swing-in-top-fwd 4s;"
  *ngIf="newGroup"
>
  <input
    type="text"
    class="input-focused word"
    placeholder=" * Csoport név"
    maxlength="25"
    minlength="3"
    #groupNameBox
    (keyup)="groupNameBoxValue($event)"
  />
  <input
    type="text"
    class="input-focused words"
    placeholder="Rövid leírás (opcionális)"
    #descriptionBox
    maxlength="25"
  />

  <app-nz-select
    class="selected"
    *ngIf="sharingLike?.length > 0"
    [options]="sharingLike"
    placeHolder="Megosztás mint..."
    (option)="sharingSelectClick($event)"
  ></app-nz-select>

  <button class="btn BtnAll saveBtn" (click)="saveGroup()" [disabled]="!up">
    Mehet
  </button>
</div>

<div
  *ngIf="info"
  class="infoGroup"
  style="-webkit-animation: swing-in-top-fwd 4s;"
>
  <label style="font-size: 1rem;">{{ info }}</label>
</div>

<div
  *ngIf="!isLoadingGroups"
  id="photo-gallery-alt"
  class="row search-results"
  style="
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #fda12b91 #4f4f5abd;
  "
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
>
  <!-- userGroups -->
  <div *ngIf="isEmptyUserGroup" style="-webkit-animation: swing-in-top-fwd 4s;">
    <label
      style="
        margin-left: 16px;
        font-size: 15px;
        color: #f8f9fa;
        background-color: #0000008f;
        padding: 4px;
      "
    >
      Még nem hoztál létre saját csoportot...
    </label>
  </div>
  <div class="row groups" *ngIf="!isEmptyUserGroup">
    <div
      class="gallery-item"
      *ngFor="let data of userGroups"
      style="-webkit-animation: swing-in-top-fwd 4s;"
    >
      <div style="width: 100%; height: 100%;">
        <div class="groups_item">
          <label class="container" *ngIf="delGroups">
            <input
              class="inp"
              type="checkbox"
              data-md-icheck
              [id]="data.gid"
              (change)="groupSelected($event)"
            />
            <span class="checkmark"></span>
          </label>
          <a
            style="cursor: pointer;"
            (click)="openAlbums(data.groupName, data.gid)"
          >
            <img
              *ngIf="data.src"
              class="groups_img"
              [src]="data.src"
              alt="image not found"
            />
            <img
              *ngIf="!data.src"
              class="groups_img"
              src="assets/img/groups001.png"
              alt="image not found"
            />
          </a>
        </div>

        <div class="groups_info">
          <a
            style="cursor: pointer;"
            (click)="openAlbums(data.groupName, data.gid)"
          >
            <small
              [style.color]="data.colorCode"
              style="text-transform: uppercase;"
            >
              {{ data.groupName }}
            </small>
            <br />
          </a>
          <small>{{ data.descriptation }}</small>
        </div>
      </div>
    </div>
  </div>

  <br />
  <hr
    style="
      padding: 8px 15px 9px;
      font-size: 15px;
      border-bottom: 0;
      border-top: 1px solid #fff;
      margin: 0;
      -webkit-animation: swing-in-top-fwd 7s;
    "
  />
  <br />

  <!-- otherGroups -->
  <div class="row groups" *ngIf="!isEmptyOtherGroups">
    <div
      class="gallery-item"
      *ngFor="let data of otherGroups"
      style="-webkit-animation: swing-in-top-fwd 4s;"
    >
      <div style="width: 100%; height: 100%;">
        <div class="groups_item" style="text-align: center; height: 67%;">
          <label class="container" *ngIf="delGroups">
            <input
              class="inp"
              type="checkbox"
              data-md-icheck
              [id]="data.gid"
              (change)="groupSelected($event)"
            />
            <span class="checkmark"></span>
          </label>
          <a
            style="cursor: pointer; font-size: 20px;"
            (click)="openAlbums(data.groupName, data.gid)"
          >
            <img
              *ngIf="data.src"
              class="groups_img"
              [src]="data.src"
              alt="image not found"
            />
            <img
              *ngIf="!data.src"
              class="groups_img"
              src="assets/img/groups001.png"
              alt="image not found"
            />
          </a>
          <div class="groups_info">
            <a
              style="cursor: pointer; font-size: 20px;"
              (click)="openAlbums(data.groupName, data.gid)"
            >
              <small
                [style.color]="data.colorCode"
                style="text-transform: uppercase;"
              >
                {{ data.groupName }}
              </small>
              <br />
            </a>
            <small>{{ data.descriptation }}</small>
            <br />
            <small style="font-style: italic; color: sandybrown;">
              Létrehozta: {{ data.createdBy }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="multi-spinner-container" *ngIf="isLoadingGroups">
  <div class="multi-spinner">
    <div class="multi-spinner">
      <div class="multi-spinner">
        <div class="multi-spinner">
          <div class="multi-spinner">
            <div class="multi-spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
