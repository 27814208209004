<div class="loginbg">
  <label class="loginText" (click)="showLgBox()">Bejelentkezés</label>
  <div
    class="loginparent"
    style="-webkit-animation: text-focus-in 1s;"
    *ngIf="loginBox"
  >
    <div class="logBox">
      <div class="center_logBox">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div
            class="form-group"
            [ngClass]="{ 'has-error': loginF.username.errors }"
          >
            <div class="">
              <input
                type="text"
                class="form-control"
                placeholder="Felhasználó név"
                formControlName="username"
                autofocus
              />
              <div *ngIf="loginF.username.errors" class="help-block"></div>
            </div>
          </div>
          <div
            class="form-group"
            [ngClass]="{ 'has-error': loginF.password.errors }"
          >
            <div class="">
              <input
                type="password"
                class="form-control"
                placeholder="Jelszó"
                formControlName="password"
              />
              <div *ngIf="loginF.password.errors" class="help-block"></div>
            </div>
          </div>
          <h6>
            <p *ngIf="message" class="text-center error errorInf">
              {{ message }}
            </p>
          </h6>
          <div class="form-group text-right loginBtn">
            <button
              class="btn BtnAll"
              type="submit"
              [disabled]="!server_init"
              [ngClass]="{ inactive: server_init === false }"
            >
              Belépés
            </button>
            <br />
            <div class="available" *ngIf="server_init">
              <label class="info">Elérhető</label>
              <img
                class="smile"
                src="assets/img/available.png"
                alt="available"
              />
            </div>
            <div class="available" *ngIf="!server_init">
              <label class="info">Most NEM elérhető</label>
              <img
                class="smile"
                src="assets/img/sad-face.png"
                alt="not available"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
