import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  logout(): void {
    localStorage.setItem('isLoggedIn', "false");
    localStorage.removeItem('currentUser');
    localStorage.removeItem('session');  
    localStorage.removeItem('token');
    localStorage.removeItem('tokenValid');
    localStorage.removeItem('picId');
    localStorage.removeItem('menu');
    localStorage.removeItem('abid');
    localStorage.removeItem('gname');
    localStorage.removeItem('gid');
    localStorage.removeItem('albumName');
  } 

}
