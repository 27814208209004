import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as $ from 'node_modules/jquery';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

$(document).ready(function()
{
  if (localStorage.getItem("isLoggedIn") === "false" 
    || localStorage.getItem("isLoggedIn") === undefined 
    || localStorage.getItem("isLoggedIn") === null) {     
      let imageUrl = environment.imageUrl;
      $('body').css('background-image', 'url(' + imageUrl + ')');
  }
});