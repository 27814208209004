<nz-select
  *ngIf="options?.length > 0"
  nzShowSearch
  nzAllowClear
  [nzPlaceHolder]="placeHolder"
  [(ngModel)]="selectedOption"
  (ngModelChange)="onSelectedClick($event)"
>
  <nz-option
    *ngFor="let option of options"
    [nzLabel]="option.value"
    [nzValue]="option"
  ></nz-option>
</nz-select>
