// Default
import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

// My services
import { AuthGuard } from '@app/_services/auth.guard'
import { EncryptService } from '@app/_services/encrypt.service'

// Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import {
  VgBufferingModule,
  VgControlsModule,
  VgCoreModule,
  VgOverlayPlayModule,
} from 'ngx-videogular'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzModalModule } from 'ng-zorro-antd/modal'

// Components
import { HeadbarComponent } from './headbar/headbar.component'
import { LoginComponent } from './login/login.component'
import { NzSelectComponent } from './_shared/components/nz-select/nz-select.component'

// Menu components
import { HomeComponent } from './home/home.component'
import { SettingsComponent } from './settings/settings.component'
import { AlbumsComponent } from './albums/albums.component'
import { ImagesComponent } from './images/images.component'
import { UploadingComponent } from './uploading/uploading.component'
import { VideosComponent } from './videos/videos.component'

// Resorvers
import { HomeResolver } from './_resorvers/home.resorver'
import { CutExtensionPipe } from './_pipes/cut-extension.pipe'

@NgModule({
  declarations: [
    AppComponent,
    HeadbarComponent,
    LoginComponent,
    HomeComponent,
    SettingsComponent,
    AlbumsComponent,
    ImagesComponent,
    UploadingComponent,
    VideosComponent,
    NzSelectComponent,
    CutExtensionPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InfiniteScrollModule,
    BrowserAnimationsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzMessageModule,
    NzModalModule,
    MatTooltipModule,
  ],
  exports: [NzSelectModule, NzMessageModule, NzModalModule, MatTooltipModule],
  providers: [AuthGuard, EncryptService, HomeResolver],
  bootstrap: [AppComponent],
})
export class AppModule {}
